// user badges
import ReadyToGrowthBadge from "../assets/images/badges/badge-ready-to-grow.svg";
import ShowCaseBadge from "../assets/images/badges/badge-showcase.svg";
import LearningBadge from "../assets/images/badges/badge-learning.svg";
import FirstTopicBadge from "../assets/images/badges/badge-first-topic.svg";
import UnlockingFirstToolBadge from "../assets/images/badges/badge-unlocking-first-tool.svg";
import StreakBadge from "../assets/images/badges/badge-streak.svg";
import DedicateToGrowthBadge from "../assets/images/badges/badge-dedicate-to-growth.svg";
import ExplorerBadge from "../assets/images/badges/badge-explorer.svg";
import FirstProgramBadge from "../assets/images/badges/badge-first-program.svg";
import SecondProgramBadge from "../assets/images/badges/badge-second-program.svg";
import ThirdProgramBadge from "../assets/images/badges/badge-third-program.svg";
import CommunityExplorerBadge from "../assets/images/badges/badge-community-explorer.svg";
import CommunityBuilderBadge from "../assets/images/badges/badge-community-builder.svg";
import CommunityExpertBadge from "../assets/images/badges/badge-community-expert.svg";
import CommunityLeaderBadge from "../assets/images/badges/badge-community-leader.svg";
import Top10Badge from "../assets/images/badges/badge-top10.svg";

// user Activities
import CompletedActivityIcon from "../assets/images/emoji-inventory.svg";
import RewardsActivityIcon from "../assets/images/emoji-military.svg";
import BadgeEarnedActivityIcon from "../assets/images/emoji-events.svg";
import CommunityActivityIcon from "../assets/images/emoji-people.svg";

export const api: any = {
  // apiUrl: "https://stgovante.aptaracorp.net/api",
  apiUrl: (() => process.env.REACT_APP_BASE_URL)(),
  // apiUrl: "http://192.168.7.163:3001/api",
  // apiUrl: "http://192.168.0.104:3001",
};

export const APP_DEFAULT_SCREEN = "/tabs/home";
export const ACCESS_TOKEN: string = "accessToken";
export const FCM_TOKEN: string = "fcmToken";
export const USER_SETTINGS: string = "userSettings";
export const INIT_STATE_APP: string = "app";
export const INIT_STATE_ONBOARD: string = "onBoarding";
export const PROFILE_STARTED: string = "profileStarted";
export const SELECTED_LANG: string = "selectedLang";
export const TNC_LINK: string =
  "https://www.accion.org/Privacy-and-Security-Policy";
export const APP_VERSION: string = "0.0.8";
export const PLAY_STORE_URL: string =
  "https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.ovante";
export const APPLE_STORE_URL: string =
  "https://itunes.apple.com/app/id378458261";
export const FIRST_LOGIN: string = "firstLogin";

export const USER_ACTIVITY_ICONS: any = {
  completed_activity: CompletedActivityIcon,
  rewards: RewardsActivityIcon,
  badge_earned: BadgeEarnedActivityIcon,
  community: CommunityActivityIcon,
};

export const USER_BADGE_ICONS: any = {
  ready_to_grow: ReadyToGrowthBadge,
  showcase: ShowCaseBadge,
  learning_into_practice: LearningBadge,
  first_topic: FirstTopicBadge,
  unlocking_first_tool: UnlockingFirstToolBadge,
  streak: StreakBadge,
  dedicated_to_growth: DedicateToGrowthBadge,
  explorer: ExplorerBadge,
  first_program: FirstProgramBadge,
  second_program: SecondProgramBadge,
  third_program: ThirdProgramBadge,
  community_explorer: CommunityExplorerBadge,
  community_builder: CommunityBuilderBadge,
  community_expert: CommunityExpertBadge,
  community_leader: CommunityLeaderBadge,
  top_10: Top10Badge,
};
