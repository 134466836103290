import "./style.scss";

import { createAnimation, IonCard, IonImg, IonLabel } from "@ionic/react";
import { useContext, useEffect, useRef, useState } from "react";

import ObserverService from "../../services/observer.service";
import BackDrawerBtn from "../../assets/images/icons/icon-polygon-back-arrow.svg";
import { AppContext } from "../../context/app.context";

interface Props {
  type: string;
  children: any;
  backBtnText?: string;
  backBtnTextKey?: any;
  hideBackDrop?: (state: boolean) => void;
}

const BottomDrawer = (props: Props) => {
  const [drawerHeight, setDrawerHeight] = useState(0);
  const [state, setState] = useState("close");
  const animationRef: any = useRef<HTMLDivElement>(null);
  const { descEnFile, descFile }: any = useContext(AppContext);
  let backBtnText: any = props.backBtnText || "Back";
  if (props.backBtnText && props.backBtnTextKey) {
    backBtnText =
      descFile[props.backBtnTextKey.toLowerCase()] ||
      descEnFile[props.backBtnTextKey.toLowerCase()] ||
      props.backBtnText;
  } else {
    backBtnText =
      descFile[backBtnText.toLowerCase()] ||
      descEnFile[backBtnText.toLowerCase()] ||
      backBtnText;
  }
  useEffect(() => {
    let observer: any = null;
    setTimeout(() => {
      if (animationRef.current) {
        setDrawerHeight(animationRef.current.clientHeight);
      }
    }, 500);
    observer = ObserverService.observable.subscribe((value: any) => {
      if (value && (value.type == props.type || value.type == "backdrop")) {
        toggleDrawer(value.data.state);
      }
    });
    return () => {
      if (observer) observer.unsubscribe();
    };
  }, [animationRef.current]);

  const toggleDrawer = (state: string) => {
    if (animationRef.current) {
      const height: number = animationRef.current.clientHeight;
      let animation = createAnimation()
        .addElement(animationRef.current)
        .duration(450);
      if (state == "close") {
        animation = animation.fromTo(
          "transform",
          `translateY(${-height + 10}px)`,
          "translateY(0)"
        );
      } else {
        animation = animation.fromTo(
          "transform",
          "translateY(0)",
          `translateY(${-height + 10}px)`
        );
      }
      animation.easing("ease-out").play();
      if (state == "open") {
        setState("close");
        props.hideBackDrop && props.hideBackDrop(true);
      } else {
        setState("open");
        props.hideBackDrop && props.hideBackDrop(false);
      }
    }
  };

  return (
    <div
      className="bottom-drawer-container"
      style={{ bottom: drawerHeight != 0 ? -drawerHeight - 5 : -1000 }}
      ref={animationRef}
    >
      <IonCard className="bottom-drawer">
        <IonLabel
          className="drawer-back"
          onClick={() => {
            // toggleDrawer(state);
            ObserverService.handleEvents(props.type, { state: "close" });
          }}
        >
          <IonImg src={BackDrawerBtn} className="back-arrow" />
          {backBtnText}
        </IonLabel>
        {props.children}
      </IonCard>
    </div>
  );
};

export default BottomDrawer;
