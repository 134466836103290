import "./style.scss";

import { IonContent, IonItem, IonList, IonPage } from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";

import ButtonComponent from "../../components/common/button-component";
import BackButton from "../../components/common/button-component/back";
import CheckBox from "../../components/common/checkbox";
import { AppContext } from "../../context/app.context";
import { OnboardingContext } from "../../context/onboarding.contex";
import { getDistList, getStateList } from "../../services/app.service";
import Backblue from "../../assets/images/icons/back-blue.svg";
import Messages from "../../config/messages";
import LanguageAppText from "../../components/languageapptext";

const DistrictList: React.FC = () => {
  const { toastMessage }: any = useContext(AppContext);
  const { onboardingData, setOnoboardingData }: any =
    useContext(OnboardingContext);
  const history: any = useHistory();
  const [distList, setDistList]: any = useState([]);
  const [disableSave, setDisableSave] = useState(
    !onboardingData || !onboardingData.district || onboardingData.district == ""
  );
  const [selectedDist, setSelectedDist]: any = useState(
    (onboardingData && onboardingData.district) || ""
  );

  useEffect(() => {
    fetchDistList();
  }, []);

  const fetchDistList = async () => {
    const resp: any = await getDistList(onboardingData.state);
    if (!resp.error) {
      setDistList(resp);
    } else {
      toastMessage(Messages.SERVER_ERROR, "primary");
    }
  };

  const handleDistSelection = (val: string) => {
    if (selectedDist === val) {
      setSelectedDist("");
      setDisableSave(true);
    } else {
      setSelectedDist(val);
      setDisableSave(false);
    }
  };

  const handleSave = () => {
    onboardingData["district"] = selectedDist;
    setOnoboardingData(onboardingData);
    history.goBack();
  };

  const renderDistList = () => {
    const view = distList.map((item: any, index: number) => {
      return (
        <div className="page-content__whitebox" key={index}>
          <CheckBox
            key={item.district}
            label={item.district}
            isChecked={selectedDist === item.district}
            onClick={() => {
              handleDistSelection(item.district);
            }}
          />
        </div>  
      );
    });

    return view;
  };

  return (
    <IonPage className="page-district">
      <IonContent fullscreen className="page-content">
        <div className="page-content__bg stretch-container"></div>
        <div className="page-content__container stretch-container">
          <div className="page-content__businessheader">
            <BackButton icon={Backblue}></BackButton>
            <LanguageAppText
              tag={"h1"}
              className="page-content__title p0"
              value={"List of District"}
              textKey={"district"}
            />
          </div>
          <div className="page-content__container-inner">
          <IonList>{renderDistList()}</IonList>

            <div className="stretch-container">
              <ButtonComponent
                name="Save"
                isEnabled={disableSave}
                onClick={handleSave}
                textKey={"save"}
              />
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default DistrictList;
