import "./style.scss";

import {
  IonContent,
  IonImg,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonRadio,
  IonRadioGroup,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";

import BottomDrawer from "../../components/bottomdrawer";
import BackDrop from "../../components/common/backdrop";
import UserProfileForm from "../../components/form/userprofile";
import { OnboardingContext } from "../../context/onboarding.contex";
import { getSalesRange } from "../../services/app.service";
import ObserverService from "../../services/observer.service";
import ButtonComponent from "../../components/common/button-component";
import RegisterHeader from "../../components/common/register-header";
import FacebookIcon from "../../assets/images/icons/icon-facebook.svg";
import GoogleIcon from "../../assets/images/icons/icon-google.svg";
import PolygonIcon from "../../assets/images/icons/icon-polygon.svg";
import LogOut from "../../assets/images/icons/icon-logout.svg";
import { useFormik } from "formik";
import { UserProfileSchema } from "../../schemas/userprofile.schema";
import { uploadFile } from "../../services/filetransfer.service";
import {
  addSocialToken,
  removePicture,
  saveProfile,
  updateDisconnectSocialMedia,
  userProfile,
} from "../../services/user.service";
import { useHistory } from "react-router";
import { AppContext } from "../../context/app.context";
import {
  getFormattedUrl,
  handleOnValueChange,
} from "../../utils/common.helper";
import {
  handleFacebookSignin,
  handleGoogleSingin,
} from "../../services/firebase.service";
import Messages from "../../config/messages";
import { chooseFromGallery, imageCapture } from "../../utils/media.helper";
import { Capacitor } from "@capacitor/core";
import Popup from "../../components/common/popup";
import LanguageAppText from "../../components/languageapptext";
import Loader from "../../components/common/loader";
let disconnectType = "",
  isBackDrop = false,
  dropDownType = "",
  isShowRemove = false;

const EditProfile: React.FC = () => {
  const SALES_TYPE = "sales";
  const GENDER_TYPE = "gender";
  const PICKER_TYPE = "picker";
  const { toastMessage, setUserDetails }: any = useContext(AppContext);
  const history: any = useHistory();
  const { onboardingData, setOnoboardingData }: any =
    useContext(OnboardingContext);
  const [isBackDropOpen, setIsBackDropOpen] = useState(false);
  const [loginWithFB, setloginWithFB] = useState(false);
  const [isDataLoaded, setDataLoaded] = useState(false);
  const [showSocialModal, setShowSocialModal] = useState(false);
  const [profileData, setProfileData]: any = useState(
    onboardingData == null ? null : onboardingData.profileData
  );
  const [profileImg, setProfileImg]: any = useState(
    onboardingData == null ? null : onboardingData.profileImg
  );
  const [isBackDropVisible, setIsBackDropVisible] = useState(false);
  const [salesRangeData, setSalesRangeData] = useState([]);
  const [gender, setGender] = useState(
    onboardingData == null ? "" : onboardingData.gender
  );
  const [salesRange, setSalesRange] = useState(
    onboardingData == null ? "" : onboardingData.salesRange
  );
  const [initialValues, setInitialValues] = useState(
    onboardingData == null ? null : onboardingData
  );
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [fbSocialLoading, setFbSocialLoading] = useState(false);
  const [googleSocialLoading, setGoogleSocialLoading] = useState(false);
  const formik: any = useFormik({
    initialValues: {
      name: "",
      gender: "",
      age: "",
      typeOfBusiness: "",
      salesRange: "",
      state: "",
      district: "",
    },
    validationSchema: UserProfileSchema,
    onSubmit: (values) => {
      handleSubmitForm(values);
    },
  });

  useEffect(() => {
    if (!isDataLoaded && initialValues) {
      Object.keys(initialValues).map((key: any, index: number) => {
        handleOnValueChange(key, initialValues[key], formik.setFieldValue);
        if (key == "salesRange") {
          setSalesRange(initialValues[key]);
        } else if (key == "gender") {
          setGender(
            (
              initialValues[key].charAt(0).toUpperCase() +
              initialValues[key].slice(1)
            ).replace(/_/g, " ")
          );
        }
      });
      setDataLoaded(true);
    }
  }, [initialValues]);

  useEffect(() => {
    fetchUserProfile();
    setTimeout(() => {
      fetchSalesRange();
    }, 250);
    document.addEventListener("ionBackButton", handleBack);

    return () => {
      document.removeEventListener("ionBackButton", handleBack);
      isBackDrop = false;
      isShowRemove = false;
    };
  }, []);

  const handleBack = (ev: any) => {
    ev.detail.register(101, () => {
      if (isBackDrop) {
        ObserverService.handleEvents(dropDownType, { state: "close" });
        isBackDrop = false;
        dropDownType = "";
      } else if (isShowRemove) {
        isShowRemove = false;
        setShowRemoveModal(false);
      } else {
        history.goBack();
      }
    });
  };

  const fetchSalesRange = async () => {
    const resp: any = await getSalesRange();
    if (!resp.error) {
      setSalesRangeData(resp);
    }
  };

  const fetchUserProfile = async () => {
    const resp: any = await userProfile();
    if (!resp.error) {
      const userData = {
        name: resp.name || "",
        gender: resp.gender || "",
        age: resp.age || "",
        typeOfBusiness: resp.typeOfBusiness || "",
        salesRange: resp.salesRange || "",
        facebookId: resp.facebookId || null,
        googleId: resp.googleId || null,
        state: resp.state || "",
        district: resp.district || "",
      };
      setInitialValues(userData);
      setOnoboardingData(userData);
      if (resp.profilePic && !profileImg)
        setProfileImg(getFormattedUrl(resp.profilePic.uri));
    } else {
      toastMessage(resp.message, "primary");
    }
  };
  const handleGenderSelect = (e: any) => {
    const val = e.detail.value;
    if (isBackDropOpen) {
      setIsBackDropOpen(false);
      isBackDrop = false;
      ObserverService.handleEvents(GENDER_TYPE, { state: "close" });
    }
    initialValues["gender"] = val.toLowerCase().replace(/ /g, "_");
    console.log(initialValues, val);
    setGender(e.detail.value);
  };

  const handleSalesRangeSelect = (e: any) => {
    if (isBackDropOpen) {
      setIsBackDropOpen(false);
      isBackDrop = false;
      ObserverService.handleEvents(SALES_TYPE, { state: "close" });
    }
    initialValues["saleRange"] = e.detail.value;
    setSalesRange(e.detail.value);
  };

  const handleSalesRangeClick = () => {
    setIsBackDropOpen(true);
    isBackDrop = true;
    dropDownType = SALES_TYPE;
    ObserverService.handleEvents(SALES_TYPE, { state: "open" });
  };

  const handleGenderClick = () => {
    setIsBackDropOpen(true);
    isBackDrop = true;
    dropDownType = GENDER_TYPE;
    ObserverService.handleEvents(GENDER_TYPE, { state: "open" });
  };

  const handleBackDrop = (state: boolean) => {
    setIsBackDropVisible(state);
    isBackDrop = state;
  };

  const handleFBLogin = async () => {
    // if (fbSocialLoading || googleSocialLoading) return;
    // setFbSocialLoading(true);
    // const tokenResp: any = await handleFacebookSignin();
    // if (!tokenResp.error) {
    //   const resp: any = await addSocialToken(tokenResp);
    //   if (!resp.error) {
    //     setInitialValues({ ...initialValues, facebookId: true });
    //     setFbSocialLoading(false);
    //     toastMessage("Facebook " + Messages.SOCIAL_ACCOUNT_ADD, "success");
    //   } else {
    //     setFbSocialLoading(false);
    //     toastMessage(resp.message, "primary");
    //   }
    // } else {
    //   setFbSocialLoading(false);
    //   toastMessage(tokenResp.message, "primary");
    // }
  };

  const handleDisconnectSocialMedia = async (type: string) => {
    if (fbSocialLoading || googleSocialLoading) return;
    if (type == "facebookId") {
      setFbSocialLoading(true);
    } else {
      setGoogleSocialLoading(true);
    }
    const response: any = await updateDisconnectSocialMedia({ [type]: null });
    if (type == "facebookId") {
      setFbSocialLoading(false);
    } else {
      setGoogleSocialLoading(false);
    }
    if (!response.error) {
      toastMessage(Messages.SOCIAL_ACCOUNT_DELETED, "success");
      setInitialValues({ ...initialValues, [type]: null });
    } else {
      toastMessage(response.message, "primary");
    }
  };

  const handleGoogleLogin = async () => {
    if (fbSocialLoading || googleSocialLoading) return;
    setGoogleSocialLoading(true);
    const tokenResp: any = await handleGoogleSingin();
    console.log("tokenResp", tokenResp);
    if (!tokenResp.error) {
      const resp: any = await addSocialToken(tokenResp);
      if (!resp.error) {
        setInitialValues({ ...initialValues, googleId: true });
        setGoogleSocialLoading(false);
        toastMessage("Google " + Messages.SOCIAL_ACCOUNT_ADD, "success");
      } else {
        setGoogleSocialLoading(false);
        toastMessage(resp.message, "primary");
      }
    } else {
      setGoogleSocialLoading(false);
      toastMessage(tokenResp.message, "primary");
    }
  };

  const handleSubmitForm = async (values: any) => {
    let thumbRes: any = null;
    const postData: any = values;
    if (profileData) {
      thumbRes = await uploadFile(
        profileData.uri || profileData.path,
        profileData.mediaType || profileData.type,
        profileData.name || ""
      );
      if (!thumbRes.error) {
        postData["profilePicFileId"] = JSON.parse(thumbRes).id;
      }
    }
    postData["gender"] = postData.gender.toLowerCase().replace(/ /g, "_");
    const response: any = await saveProfile(postData);
    if (!response.error) {
      setUserDetails(response);
      setOnoboardingData(null);
      isBackDrop = false;
      isShowRemove = false;
      history.replace("/settings");
    } else {
      toastMessage(response.message, "primary");
    }
  };

  const handlePickerSelect = async (picker: string) => {
    if (isBackDropOpen) {
      setIsBackDropOpen(false);
      isBackDrop = false;
      ObserverService.handleEvents(PICKER_TYPE, { state: "close" });
    }
    if (picker === "camera") {
      const imageData = await imageCapture();
      if (imageData) {
        setProfileImg(Capacitor.convertFileSrc(imageData.path));
        setProfileData(imageData);
      }
    } else if (picker === "gallery") {
      const imageData = await chooseFromGallery("image/*");
      if (imageData) {
        setProfileImg(imageData.dataURI);
        setProfileData(imageData);
      }
    } else {
      isShowRemove = true;
      setShowRemoveModal(true);
    }
  };

  const handlePickerClick = () => {
    setIsBackDropOpen(true);
    isBackDrop = true;
    dropDownType = PICKER_TYPE;
    ObserverService.handleEvents(PICKER_TYPE, { state: "open" });
  };

  const onRemovePicture = async () => {
    const resp: any = await removePicture({});
    if (!resp.error) {
      // isShowRemove = false;
      // setShowRemoveModal(false);
      setProfileImg(null);
      setProfileData(null);
    } else {
      toastMessage(Messages.SERVER_ERROR, "primary");
    }
  };
  const renderGender = () => {
    return (
      <IonList className="ion-list">
        <IonRadioGroup
          className="ion-radio-group"
          value={gender}
          onIonChange={handleGenderSelect}
        >
          <IonItem>
            <IonLabel>Male</IonLabel>
            <IonRadio slot="end" value="Male" />
          </IonItem>
          <IonItem>
            <IonLabel>Female</IonLabel>
            <IonRadio slot="end" value="Female" />
          </IonItem>
          <IonItem>
            <IonLabel>Would rather not say</IonLabel>
            <IonRadio slot="end" value="Would rather not say" />
          </IonItem>
        </IonRadioGroup>
      </IonList>
    );
  };

  const renderSalesItems = () => {
    return salesRangeData.map((item: any, index: any) => {
      return (
        <IonItem key={index}>
          <IonLabel>{item}</IonLabel>
          <IonRadio slot="end" value={item} />
        </IonItem>
      );
    });
  };

  const renderSalesRange = () => {
    return (
      <IonList className="ion-list">
        <IonRadioGroup
          className="ion-radio-group"
          value={salesRange}
          onIonChange={handleSalesRangeSelect}
        >
          {renderSalesItems()}
        </IonRadioGroup>
      </IonList>
    );
  };

  const renderPicker = () => {
    return (
      <IonList className="ion-list">
        <IonRadioGroup className="ion-radio-group">
          {profileImg && (
            <IonItem
              onClick={() => {
                handlePickerSelect("remove");
              }}
            >
              <IonLabel>Remove picture</IonLabel>
            </IonItem>
          )}
          <IonItem
            onClick={() => {
              handlePickerSelect("camera");
            }}
          >
            <IonLabel>Take a picture</IonLabel>
          </IonItem>
          <IonItem
            onClick={() => {
              handlePickerSelect("gallery");
            }}
          >
            <IonLabel>Choose from gallery</IonLabel>
          </IonItem>
        </IonRadioGroup>
      </IonList>
    );
  };

  return (
    <>
      <IonPage className="page-edit-profile">
        <RegisterHeader
          pageName="Edit profile"
          textKey={"edit_profile"}
          backBtn={true}
        ></RegisterHeader>
        <IonContent fullscreen={true} className="page-content">
          {salesRangeData.length > 0 && (
            <>
              <UserProfileForm
                formik={formik}
                initialValues={initialValues}
                gender={gender}
                salesRange={salesRange}
                profileImg={profileImg}
                profileData={profileData}
                handleGenderClick={handleGenderClick}
                handleSalesRangeClick={handleSalesRangeClick}
                handleEditPickClick={handlePickerClick}
              ></UserProfileForm>
              <div className="page-content">
                <LanguageAppText
                  tag={"h1"}
                  className="page-content__title"
                  value={"More ways to access your account"}
                  textKey={"ways_to_access"}
                />
                {initialValues && initialValues.facebookId ? (
                  <div className="page-content__whitebox log-in-fb">
                    <IonImg
                      className="whitebox__socialicon"
                      src={FacebookIcon}
                    />
                    <p className="whitebox__content">
                      <LanguageAppText
                        tag={"span"}
                        value={"Connected"}
                        textKey={"connected"}
                      />
                      <span className="fb-user-name font-medium mt-5">
                        {initialValues.name}
                      </span>
                    </p>
                    <IonImg
                      src={LogOut}
                      className="icon-logout"
                      onClick={() => {
                        disconnectType = "facebook";
                        setShowSocialModal(true);
                        // handleDisconnectSocialMedia("facebookId");
                      }}
                    />
                  </div>
                ) : (
                  <div
                    className="page-content__whitebox"
                    onClick={handleFBLogin}
                  >
                    <IonImg
                      className="whitebox__socialicon"
                      src={FacebookIcon}
                    />
                    <LanguageAppText
                      tag={"p"}
                      className="whitebox__content"
                      value={"Connect Facebook"}
                      textKey={"connect_facebook"}
                    />
                    {fbSocialLoading ? (
                      <Loader />
                    ) : (
                      <IonImg src={PolygonIcon} />
                    )}
                  </div>
                )}
                {initialValues && initialValues.googleId ? (
                  <div className="page-content__whitebox log-in-fb">
                    <IonImg className="whitebox__socialicon" src={GoogleIcon} />
                    <p className="whitebox__content">
                      <LanguageAppText
                        tag={"span"}
                        value={"Connected"}
                        textKey={"connected"}
                      />
                      <span className="fb-user-name font-medium mt-5">
                        {initialValues.name}
                      </span>
                    </p>
                    <IonImg
                      src={LogOut}
                      className="icon-logout"
                      onClick={() => {
                        disconnectType = "google";
                        setShowSocialModal(true);
                        // handleDisconnectSocialMedia("facebookId");
                      }}
                      // onClick={() => handleDisconnectSocialMedia("googleId")}
                    />
                  </div>
                ) : (
                  <div
                    className="page-content__whitebox"
                    onClick={handleGoogleLogin}
                  >
                    <IonImg className="whitebox__socialicon" src={GoogleIcon} />
                    <LanguageAppText
                      tag={"p"}
                      className="whitebox__content"
                      value={"Connect Google"}
                      textKey={"connect_google"}
                    />
                    {googleSocialLoading ? (
                      <Loader />
                    ) : (
                      <IonImg src={PolygonIcon} />
                    )}
                  </div>
                )}
              </div>
              <div className="stretch-container">
                <ButtonComponent
                  name="Save"
                  textKey={"save"}
                  onClick={formik.handleSubmit}
                ></ButtonComponent>
              </div>
            </>
          )}
        </IonContent>
        <BackDrop
          backdropVisible={isBackDropVisible}
          isOutsideClick={true}
          onOutClick={() => {
            isBackDrop = false;
            dropDownType = "";
          }}
        ></BackDrop>
      </IonPage>

      {salesRangeData.length > 0 && (
        <div
          style={{
            position: "absolute",
            bottom: 0,
            zIndex: 101,
            width: "100%",
          }}
        >
          <BottomDrawer type={SALES_TYPE} hideBackDrop={handleBackDrop}>
            {renderSalesRange()}
          </BottomDrawer>
          <BottomDrawer type={GENDER_TYPE} hideBackDrop={handleBackDrop}>
            {renderGender()}
          </BottomDrawer>
          <BottomDrawer type={PICKER_TYPE} hideBackDrop={handleBackDrop}>
            {renderPicker()}
          </BottomDrawer>
        </div>
      )}

      {showSocialModal && (
        <Popup
          customClass="delete-comment-popup"
          onClose={() => {
            setShowSocialModal(false);
          }}
        >
          <div className="delete-popup-container">
            <LanguageAppText
              tag="div"
              className="delete-popup-text text-light-black font-medium mb-20"
              textKey={"delete_social_account"}
              value="Do you want to disconnect your SOCIAL SERVICE account?"
            />

            <div className="delete-popup-button-wrapper">
              <ButtonComponent
                name="Yes"
                textKey={"yes"}
                onClick={() => {
                  console.log("dis", disconnectType);
                  if (disconnectType === "facebook") {
                    handleDisconnectSocialMedia("facebookId");
                  } else {
                    handleDisconnectSocialMedia("googleId");
                  }
                  disconnectType = "";
                  setShowSocialModal(false);
                }}
                customClass="delete-popup-button mr-10"
              ></ButtonComponent>
              <ButtonComponent
                name="No"
                textKey={"no"}
                outline
                onClick={() => {
                  disconnectType = "";
                  setShowSocialModal(false);
                }}
                customClass="delete-popup-button ml-10"
              ></ButtonComponent>
            </div>
          </div>
        </Popup>
      )}
      {showRemoveModal && (
        <Popup
          customClass="delete-comment-popup"
          onClose={() => {
            setShowRemoveModal(false);
            isShowRemove = false;
          }}
        >
          <div className="delete-popup-container">
            <LanguageAppText
              tag="div"
              className="delete-popup-text text-light-black font-medium mb-20"
              textKey={"delete_profile_picture"}
              value="Are you sure that you want to remove profile picture?"
            />

            <div className="delete-popup-button-wrapper">
              <ButtonComponent
                name="Yes"
                textKey={"yes"}
                onClick={() => {
                  onRemovePicture();
                  setShowRemoveModal(false);
                  isShowRemove = false;
                }}
                customClass="delete-popup-button mr-10"
              ></ButtonComponent>
              <ButtonComponent
                name="No"
                textKey={"no"}
                outline
                onClick={() => {
                  setShowRemoveModal(false);
                  isShowRemove = false;
                }}
                customClass="delete-popup-button ml-10"
              ></ButtonComponent>
            </div>
          </div>
        </Popup>
      )}
    </>
  );
};

export default EditProfile;
