import { IonContent, IonImg, IonPage, useIonRouter } from "@ionic/react";
import React, { useContext } from "react";
import { useHistory } from "react-router";
import ButtonComponent from "../../components/common/button-component";
import RegisterHeader from "../../components/common/register-header";
import LanguageAppText from "../../components/languageapptext";
import { TNC_LINK } from "../../config/appconfig";
import { AppContext } from "../../context/app.context";
import { agreeToTOC } from "../../services/user.service";
import "./style.scss";

const TermsAndCondition: React.FC = () => {
  const { toastMessage }: any = useContext(AppContext);
  const history: any = useHistory();

  const handleAcceptTOC = async () => {
    const resp: any = await agreeToTOC();
    if (!resp.error) {
      history.replace({
        pathname: "/onboarding",
      });
    } else {
      toastMessage(resp.message, "primary");
    }
  };

  return (
    <IonPage className="page-t-and-c">
      <RegisterHeader
        pageName="Terms & conditions"
        textKey={"tnc_amp"}
        backBtn={true}
      ></RegisterHeader>
      <IonContent fullscreen={true} className="page-content">
        <div>
          <LanguageAppText
            tag={"h1"}
            className="page-content__title"
            value={"Before we begin"}
            textKey={"before_we_begin"}
          />
          <LanguageAppText
            tag={"p"}
            className="page-content__infotext"
            value={
              "Before using Ovante, you must accept the terms and conditions."
            }
            textKey={"tnc_accept"}
          />
          <LanguageAppText
            tag={"p"}
            className="page-content__infotext"
            value={
              "These explain important information, including but not limited to:"
            }
            textKey={"tnc_important_information"}
          />
          <ul className="m0">
            <LanguageAppText
              tag={"li"}
              className="page-content__infotext p0"
              value={"How we collect information about you"}
              textKey={"tnc_collect_information"}
            />
            <LanguageAppText
              tag={"li"}
              className="page-content__infotext"
              value={"How we share with others"}
              textKey={"tnc_share_information"}
            />
          </ul>

          <p className="page-content__infotext p0">
            <div
              className="page-content__link"
              onClick={() => {
                history.push("/terms-of-use");
              }}
            >
              <LanguageAppText
                tag={"span"}
                value={"Read the terms & conditions"}
                textKey={"tnc_read"}
              />
            </div>
          </p>
        </div>
        <div className="page-content__button">
          <ButtonComponent
            name="I agree to the terms & conditions"
            textKey={"tnc_agree"}
            onClick={handleAcceptTOC}
          ></ButtonComponent>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default TermsAndCondition;
