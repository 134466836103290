import httpService from "./http.service";

export const getTypeOfBusinesses = () => {
  const response = httpService.execute({
    url: "/global/type-of-business",
  });

  return response;
};

export const getSalesRange = () => {
  const response = httpService.execute({
    url: "/global/sales-range",
  });

  return response;
};

export const getLanguages = () => {
  const response = httpService.execute({
    url: "/language",
  });
  return response;
};

export const getInfo = () => {
  const response = httpService.execute({
    url: "/info",
  });
  return response;
};

export const getAppSlides = () => {
  const response = httpService.execute({
    url: "/onboarding",
  });
  return response;
};

export const getStateList = () => {
  const response = httpService.execute({
    url: "/state-district",
  });

  return response;
};

export const getDistList = (state: string) => {
  const response = httpService.execute({
    url: `/state-district/district/${state}`,
  });

  return response;
};
