import { IonContent, IonImg, IonPage } from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import InstallApp from "../../assets/images/install-app.svg";
import PlayStoreIcon from "../../assets/images/icons/ic-google-play-store.svg";
import AppStoreIcon from "../../assets/images/icons/ic-app-store.svg";
import "./style.scss";
import { getUserCurrentProgram } from "../../services/program.service";
import { AppContext } from "../../context/app.context";
import Messages from "../../config/messages";
import Loader from "../../components/common/loader";
import { redirectToStore } from "../../utils/common.helper";
import { APPLE_STORE_URL, PLAY_STORE_URL } from "../../config/appconfig";
import { isPlatform } from "@ionic/core";
import LanguageAppText from "../../components/languageapptext";

const InstallOvante = () => {
  const [loading, setLoading] = useState(true);
  const [metaData, setMetaData]: any = useState(null);
  const [title, setTitle] = useState("");
  const { toastMessage, language }: any = useContext(AppContext);

  useEffect(() => {
    fetchUserCurrentProgram();
  }, []);
  const fetchUserCurrentProgram = async () => {
    const resp: any = await getUserCurrentProgram();
    if (!resp.error) {
      if (!resp.defaultProgram) {
        if (resp.program) {
          try {
            let parsedData = JSON.parse(resp.program.nodeFieldData.metaData);
            if (parsedData) {
              let metaData = parsedData[language] || parsedData["en"];
              setMetaData(metaData);
            }
            let parsedTitleData = JSON.parse(resp.program.nodeFieldData.title);
            if (parsedTitleData) {
              let title = parsedTitleData[language] || parsedTitleData["en"];
              setTitle(title);
            }
          } catch (error) {}
        }
      }
    } else {
      toastMessage(Messages.SERVER_ERROR, "primary");
    }
    setLoading(false);
  };

  const onOpenApp = () => {
    // setTimeout(function () {
    //   if (isPlatform("ios")) {
    //     window.location.href = APPLE_STORE_URL;
    //   } else {
    //     window.location.href = PLAY_STORE_URL;
    //   }
    // }, 25);
    // window.location.href = "ovante://";

    var original_start: any = new Date();
    var lag = 1250;

    var deeplink_url = "ovante://";
    window.location.href = deeplink_url;

    setTimeout(function () {
      var newTime: any = new Date();
      var time_spent_waiting: any = newTime - original_start;
      console.log(time_spent_waiting);
      if (time_spent_waiting > 2.0 * lag) {
        // We can assume they have the app, so do nothing.
      } else {
        // That was too fast so we can assume they don't have the app.
        if (isPlatform("ios")) {
          window.location.href = APPLE_STORE_URL;
        } else {
          window.location.href = PLAY_STORE_URL;
        }
      }
    }, lag);
  };

  return (
    <IonPage className="page-install-app large-header abstract-img-light-blue">
      <IonContent className="page-content">
        {loading ? (
          <Loader customClass="loader-page-center"></Loader>
        ) : (
          <>
            {metaData && (
              <>
                <div className="page-install-app__container text-white">
                  <div className="install-app-img__container mb-15">
                    <IonImg src={InstallApp} className="install-app-img" />
                  </div>
                  <LanguageAppText
                    tag={"div"}
                    className="install-app-title font-medium mb-25"
                    value={"Install the Ovante App"}
                    textKey={"install_app"}
                  />
                  <LanguageAppText
                    tag={"div"}
                    className="install-app__user mb-25"
                    value={`${metaData.expertName} is ready to teach you ${title}`}
                    textKey={"install_app"}
                    metaData={{
                      expertName: metaData.expertName,
                      programName: title,
                    }}
                  />
                  <LanguageAppText
                    tag={"div"}
                    className="install-app__desc"
                    value={
                      "Install the Ovante App on your phone to get started today."
                    }
                    textKey={"install_app_get_started"}
                  />
                </div>
                {/* <div className="page-install-app__bottom-container stretch-container bg-light-orange mt-0">
                  <LanguageAppText
                    tag={"div"}
                    className="install-app__download-text font-medium mb-15 text-blue"
                    value={"Download the app"}
                    textKey={"download_app"}
                  />
                  <IonImg
                    onClick={() => {
                      redirectToStore("android");
                    }}
                    className="web-playstore mb-15"
                    src={PlayStoreIcon}
                  />
                  <IonImg
                    onClick={() => {
                      redirectToStore("ios");
                    }}
                    className="web-appstore mb-15"
                    src={AppStoreIcon}
                  />
                  <div
                    onClick={() => {
                      onOpenApp();
                    }}
                  >
                    <LanguageAppText
                      tag={"div"}
                      className="install-app__already-text font-medium text-light-blue"
                      value={"I already have the app"}
                      textKey={"already_have_app"}
                    />
                  </div>
                </div> */}
              </>
            )}
          </>
        )}
      </IonContent>
      <div className="page-install-app__bottom-container stretch-container bg-light-orange mt-0">
        <LanguageAppText
          tag={"div"}
          className="install-app__download-text font-medium mb-15 text-blue"
          value={"Download the app"}
          textKey={"download_app"}
        />
        <IonImg
          onClick={() => {
            redirectToStore("android");
          }}
          className="web-playstore mb-15"
          src={PlayStoreIcon}
        />
        <IonImg
          onClick={() => {
            redirectToStore("ios");
          }}
          className="web-appstore mb-15"
          src={AppStoreIcon}
        />
        <div
          onClick={() => {
            onOpenApp();
          }}
        >
          <LanguageAppText
            tag={"div"}
            className="install-app__already-text font-medium text-light-blue"
            value={"I already have the app"}
            textKey={"already_have_app"}
          />
        </div>
      </div>
    </IonPage>
  );
};
export default InstallOvante;
