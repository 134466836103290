import firebase from "firebase/app";
import 'firebase/auth';
import "@codetrix-studio/capacitor-google-auth";
import { FacebookLoginResponse } from '@rdlabo/capacitor-facebook-login';
import { Capacitor, Plugins } from '@capacitor/core';

const firebaseConfig = {
    apiKey: "AIzaSyBWKNEQ6Ji1W-IuXJebllJL-Okg_uEK3qA",
    authDomain: "ovante-9c181.firebaseapp.com",
    projectId: "ovante-9c181",
    storageBucket: "ovante-9c181.appspot.com",
    messagingSenderId: "846356035668",
    appId: "1:846356035668:web:d59d2a2644b5c6f25815c1",
    measurementId: "G-PLQWWW2KJ5"
  }

export const firebaseInit = () => {
  if (firebase.apps.length === 0) {
    firebase.initializeApp(firebaseConfig)
  }
}

const webGoogleSignIn = async() => {
  try{
    const provider = new firebase.auth.GoogleAuthProvider();
    const resp: any = await firebase.auth().signInWithPopup(provider)
    const credentialResp = resp.credential;
    const idToken = credentialResp.idToken;
    var credential = await firebase.auth.GoogleAuthProvider.credential(idToken);
    const respSocial: any = await webSocialLogin(credential)
    return respSocial;
  } catch(e: any){
    return {
      error: true,
      message: e.message
    }
  }
}

const webFacebookSignIn = async() => {
  try{
    const provider = new firebase.auth.FacebookAuthProvider();
    const resp: any = await firebase.auth().signInWithPopup(provider)
    const credentialResp = resp.credential;
    // This gives you a Google Access Token. You can use it to access the Google API.
    const token = credentialResp.accessToken;
    const credential = await firebase.auth.FacebookAuthProvider.credential(token);
    const respSocial: any = await webSocialLogin(credential)
    return respSocial;
  } catch(e: any){
    return {
      error: true,
      message: e.message
    }
  }
}

const mobileFacebookLogin = async() => {
  try{
    const { FacebookLogin } = Plugins;
    const FACEBOOK_PERMISSIONS = ['email'];
    const result: any = await <FacebookLoginResponse>FacebookLogin.login({ permissions: FACEBOOK_PERMISSIONS });
    const token = result.accessToken.token
    const credential = await firebase.auth.FacebookAuthProvider.credential(token);
    const respSocial: any = await webSocialLogin(credential)
    return respSocial;
  } catch(e: any){
    return {
      error: true,
      message: e.message
    }
  }
}

const mobileGoogleLogin = async() => {
  try{
    const res: any = await Plugins.GoogleAuth.signIn();
    const idToken = res.authentication.idToken;
    var credential = await firebase.auth.GoogleAuthProvider.credential(idToken);
    const respSocial: any = await webSocialLogin(credential)
    return respSocial;
  } catch(e: any){
    return {
      error: true,
      message: e.message
    }
  }
}

const webSocialLogin = async (credential: any) => {
  const respSignIn = await firebase.auth().signInWithCredential(credential);
  const firebaseToken = await firebase.auth().currentUser?.getIdToken()
  return { firebaseToken }
}

export const handleFacebookSignin = async() => {
  let resp: any = null
  if(Capacitor.getPlatform() == "web"){
    resp = await webFacebookSignIn();
  } else {
    resp = await mobileFacebookLogin();
  }
  return resp
}

export const handleGoogleSingin = async() => {
  let resp: any = null
  if(Capacitor.getPlatform() == "web"){
    resp = await webGoogleSignIn();
  } else {
    resp = await mobileGoogleLogin();
  }
  return resp
}

//singout to close previous facebook session
export const handleFacebookSignout = async () => {
  if(Capacitor.getPlatform() == "web"){
    await firebase.auth().signOut();
  } else {
    await Plugins.FacebookLogin.logout();
  }
}

//singout to close previous google session
export const handleGoogleSignout = async () => {
  if(Capacitor.getPlatform() == "web"){
    await firebase.auth().signOut();
  } else {
    await Plugins.GoogleAuth.signOut();
  }  
}