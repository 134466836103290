import React, { useEffect, useState } from "react";
import { useIonToast } from "@ionic/react";
import storageSevice from "../services/storage.sevice";
import {
  ACCESS_TOKEN,
  INIT_STATE_APP,
  INIT_STATE_ONBOARD,
  SELECTED_LANG,
  USER_SETTINGS,
} from "../config/appconfig";
import { Capacitor } from "@capacitor/core";
import { userProfile } from "../services/user.service";
// import { getSelectedLang, seti18nLocal } from '../services/translation.service'
import { Plugins } from "@capacitor/core";
import { getFileDirectory, getStringByLanguage } from "../utils/common.helper";
import { File } from "@ionic-native/file";

const { App } = Plugins;
export const AppContext = React.createContext({});

type Props = {
  children: any;
};

export const AppProvider = (props: Props) => {
  const [present, dismiss] = useIonToast();
  const [themeType, setThemeType] = useState("light");
  const [language, setLanguage] = useState("hi");
  const [settings, setSettings] = useState(null);
  const [lottieOption, setLottieOption]: any = useState(null);
  const [isLottiePlay, setIsLottiePlay] = useState(false);
  const [hasToken, setHasToken] = useState("");
  const [initAppState, setInitAppState] = useState(INIT_STATE_ONBOARD);
  const [userDetails, setUserDetails]: any = useState(null);
  const [isBackground, setIsBackground] = useState(false);
  const [descEnFile, setDescEnFile]: any = useState({});
  const [descFile, setDescFile]: any = useState({});

  useEffect(() => {
    // getUserDetails();
    getLang();
    userSettings();
    checkTokenAvailable();
    App.addListener("appStateChange", ({ isActive }) => {
      setIsBackground(!isActive);
    });
  }, []);

  const getDescriptorFile = async (langaugeCode: string) => {
    console.log("langaugeCode", langaugeCode);
    let desc_file = `descriptor_${langaugeCode}`;
    let desc_en_file = `descriptor_en`;

    try {
      const enDescData = await File.readAsText(
        getFileDirectory(),
        `${desc_en_file}.json`
      );

      setDescEnFile(JSON.parse(enDescData));
    } catch (error) {
      console.log("en lang error", error);
    }
    try {
      const langDescData = await File.readAsText(
        getFileDirectory(),
        `${desc_file}.json`
      );
      setDescFile(JSON.parse(langDescData));
    } catch (error) {
      console.log(" lang error", error);
      setDescFile({});
    }
  };
  const checkTokenAvailable = async () => {
    const token = await storageSevice.get(ACCESS_TOKEN);
    setHasToken(token ? "true" : "false");
  };

  const userSettings = async () => {
    const settingsVal = await storageSevice.get(USER_SETTINGS);
    if (settingsVal) {
      setSettings(JSON.parse(settingsVal));
    }
  };

  const toastMessage = (message: string, variant = "default") => {
    message = getStringByLanguage(message, descFile, descEnFile);
    present({
      message: message,
      color: variant,
      duration: 2000,
      cssClass: Capacitor.getPlatform() != "web" ? "toast-message" : "",
    });
  };

  const playLottieAnimation = (animationData: any) => {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };
    setLottieOption(defaultOptions);
    setIsLottiePlay(true);
  };

  const stopLottieAnimation = () => {
    setLottieOption(null);
    setIsLottiePlay(false);
  };

  const getLang = async () => {
    const lang = await storageSevice.get(SELECTED_LANG);
    if (lang) {
      setLanguage(lang);
    } else {
      setLanguage("hi");
    }
  };

  const lanChange = async (lang: string) => {
    // seti18nLocal(lang)
    await storageSevice.set(SELECTED_LANG, lang);
    setLanguage(lang);
  };

  const setAppState = (state: string) => {
    setInitAppState(state);
  };
  return (
    <>
      {hasToken !== "" && (
        <AppContext.Provider
          value={{
            themeType,
            setThemeType,
            toastMessage,
            settings,
            setSettings,
            language,
            lanChange,
            isLottiePlay,
            lottieOption,
            playLottieAnimation,
            stopLottieAnimation,
            hasToken,
            setHasToken,
            initAppState,
            setAppState,
            userDetails,
            setUserDetails,
            isBackground,
            descEnFile,
            descFile,
            getDescriptorFile,
            setInitAppState,
          }}
        >
          {props.children}
        </AppContext.Provider>
      )}
    </>
  );
};
