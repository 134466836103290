import {
  CaptureVideoOptions,
  MediaCapture,
  CaptureError,
  CaptureImageOptions,
} from "@ionic-native/media-capture";
import {
  CreateThumbnailOptions,
  TranscodeOptions,
  VideoEditor,
} from "@ionic-native/video-editor";
import { random } from "./common.helper";
import { Chooser } from "@ionic-native/chooser";
import { FilePath } from "@awesome-cordova-plugins/file-path";
import { Capacitor } from "@capacitor/core";
export const videoRecorder = async (options: CaptureVideoOptions) => {
  const retObj = { path: "", compressed: "", thumbnail: "" };
  try {
    const data: any = await MediaCapture.captureVideo(options);
    console.log("MediaCapture", data);
    const resp: any = parseMediaResponse(data);
    const compressed = await compressVideo(resp.path);
    const thumbnail = await createThumbnail(`file://${compressed}`);
    return { path: resp.path, compressed, thumbnail };
  } catch (error) {
    console.log(error);
  }
  return retObj;
};

export const compressVideo = async (fileUri: string) => {
  try {
    let temp = fileUri;
    if (Capacitor.getPlatform() === "android") {
      temp = await FilePath.resolveNativePath(fileUri);
    }
    const transcodeOptions: TranscodeOptions = {
      fileUri: temp,
      outputFileName: `${random()}`,
      outputFileType: VideoEditor.OutputFileType.MPEG4,
      saveToLibrary: false,
    };
    const compressed = await VideoEditor.transcodeVideo(transcodeOptions);
    console.log("video transcode success", compressed);
    console.log(compressed);
    return compressed;
  } catch (error) {
    console.log(error);
  }
  return "";
};

export const createThumbnail = async (fileUri: string) => {
  console.log("fileUri", fileUri);
  const thumbnailOptions: CreateThumbnailOptions = {
    height: 1024,
    width: 1024,
    quality: 100,
    fileUri, // looks something like this : http://example.com/filename.mp4
    outputFileName: `${random()}`,
  };
  try {
    const thumbnail = await VideoEditor.createThumbnail(thumbnailOptions);
    return thumbnail;
  } catch (error) {
    console.log(error);
  }
  return "";
};

export const imageCapture = async (options?: CaptureImageOptions) => {
  try {
    const data: any = await MediaCapture.captureImage(options || {});
    console.log("data", JSON.stringify(data));
    return parseMediaResponse(data);
  } catch (e) {
    console.log("error");
    return null;
  }
};

export const chooseFromGallery = async (
  options?: any,
  doCompress?: boolean
): Promise<any> => {
  try {
    const respFile = await Chooser.getFile(options);
    if (doCompress && respFile) {
      const compressed = await compressVideo(respFile?.uri);
      const thumbnail = await createThumbnail(`file://${compressed}`);
      return { path: respFile.uri, compressed, thumbnail };
    }
    return respFile;
  } catch (e) {
    console.log("error");
    return null;
  }
};

const parseMediaResponse = (data: any) => {
  let path,
    len = data.length;
  for (let i = 0; i < len; i += 1) {
    path = {
      path: data[i].fullPath,
      type: data[i].type,
    };
  }
  return path;
};
