import "./style.scss";

import { IonContent, IonList, IonPage, IonTextarea } from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";

import ButtonComponent from "../../components/common/button-component";
import BackButton from "../../components/common/button-component/back";
import CheckBox from "../../components/common/checkbox";
import InputText from "../../components/common/inputtext";
import { AppContext } from "../../context/app.context";
import { OnboardingContext } from "../../context/onboarding.contex";
import { getTypeOfBusinesses } from "../../services/app.service";
import Backblue from "../../assets/images/icons/back-blue.svg";
import LanguageAppText from "../../components/languageapptext";

const BusinessType: React.FC = () => {
  const { toastMessage }: any = useContext(AppContext);
  const { onboardingData, setOnoboardingData }: any =
    useContext(OnboardingContext);
  const history: any = useHistory();
  const [typeOfBusinesses, setTypeOfBuissnesses]: any = useState([]);
  const [showNotListedBox, setShowNotListedBox] = useState(false);
  const [disableSave, setDisableSave] = useState(true);
  const [selectedBusinesses, setSelectedBusiness]: any = useState([]);
  const [notListedBusiness, setNotListedBusiness] = useState("");

  useEffect(() => {
    fetchBusinesses();
  }, []);

  const fetchBusinesses = async () => {
    const resp: any = await getTypeOfBusinesses();
    if (!resp.error) {
      let filteredData: any;
      const onboardingBusinesses = onboardingData
        ? onboardingData.typeOfBusiness == ""
          ? []
          : onboardingData && onboardingData.typeOfBusiness.split("|")
        : [];
      filteredData = resp.map((data: any, index: number) => {
        const filteredItems = data.data.map((item: any, i: number) => {
          const itemVal = {
            value: item,
            state: false,
          };
          if (onboardingBusinesses.length > 0) {
            const index = onboardingBusinesses.indexOf(item);
            if (index > -1) {
              selectedBusinesses.push(item);
              onboardingBusinesses.splice(index, 1);
              itemVal["state"] = true;
            }
          }
          return itemVal;
        });
        data["data"] = filteredItems;

        return data;
      });
      if (onboardingBusinesses.length > 0) {
        setNotListedBusiness(onboardingBusinesses.join(","));
        setShowNotListedBox(true);
      }
      setTypeOfBuissnesses(filteredData);
    } else {
      toastMessage(resp.message, "primary");
    }
  };

  const handleDescribeBusinessChnage = (val: string) => {
    if (selectedBusinesses.length == 0 && val == "") {
      setDisableSave(true);
    } else {
      setDisableSave(false);
    }
    setNotListedBusiness(val);
  };

  const handleBusinessSelection = (type: string, val: string) => {
    const selected: any = selectedBusinesses;
    const indexOf = selected.indexOf(val);
    const tempTypeofBusinesses = typeOfBusinesses.map(
      (data: any, index: number) => {
        if (data.type == type) {
          const updatedData = data.data.map((item: any, i: number) => {
            if (item.value == val && indexOf > -1) {
              item.state = false;
            } else if (item.value == val && indexOf == -1) {
              item.state = true;
            }
            return item;
          });
          return {
            type: data.type,
            data: updatedData,
          };
        }
        return data;
      }
    );
    if (indexOf > -1) {
      selected.splice(indexOf, 1);
    } else {
      selected.push(val);
    }
    if (selected.length == 0 && notListedBusiness == "") {
      setDisableSave(true);
    } else {
      setDisableSave(false);
    }
    setTypeOfBuissnesses(tempTypeofBusinesses);
    setSelectedBusiness(selected);
  };

  const handleSave = () => {
    let businesses = "";
    if (selectedBusinesses.length > 0) {
      businesses = selectedBusinesses.join("|");
    }
    if (notListedBusiness != "") {
      if (businesses != "") {
        businesses = businesses + "|" + notListedBusiness;
      } else {
        businesses = notListedBusiness;
      }
    }
    onboardingData["typeOfBusiness"] = businesses;
    setOnoboardingData(onboardingData);
    history.goBack();
  };

  const renderBusinessItems = (type: string, data: any, index: number) => {
    return data.map((item: any, itemIndex: number) => {
      return (
        <CheckBox
          key={item.value}
          label={item.value}
          isChecked={item.state}
          onClick={() => {
            handleBusinessSelection(type, item.value);
          }}
        />
      );
    });
  };

  const renderBusinessTypes = () => {
    const view = typeOfBusinesses.map((data: any, index: number) => {
      return (
        <div className="page-content__whitebox" key={index}>
          <h1 className="page-content__title">{data.type}</h1>
          <IonList>{renderBusinessItems(data.type, data.data, index)}</IonList>
        </div>
      );
    });

    return view;
  };

  return (
    <IonPage className="page-business">
      <IonContent fullscreen className="page-content">
        <div className="page-content__bg stretch-container"></div>
        <div className="page-content__container stretch-container">
          <div className="page-content__businessheader">
            <BackButton icon={Backblue}></BackButton>
            <LanguageAppText
              tag={"h1"}
              className="page-content__title p0"
              value={"Type of Business"}
              textKey={"type_of_business"}
            />
          </div>
          <div className="page-content__container-inner">
            {renderBusinessTypes()}
            {/* <div className="page-content__whitebox businees-cell"> */}
            <div
              className={`page-content__whitebox businees-cell ${
                showNotListedBox ? "describe-business" : ""
              }`}
            >
              {showNotListedBox ? (
                <>
                  <LanguageAppText
                    tag={"h1"}
                    className="page-content__title"
                    value={"What does your business sell?"}
                    textKey={"business_sell"}
                  />
                  {/* <IonTextarea
                    autoGrow={true}
                    className="ion-item__input"
                    name="notListedBusiness"
                    placeholder="Describe your business"
                    value={notListedBusiness}
                    //onChange={handleDescribeBusinessChnage}
                  ></IonTextarea> */}
                  <InputText
                    name="notListedBusiness"
                    placeholder="Describe your business"
                    placeholderKey={"describe_business"}
                    value={notListedBusiness}
                    onChange={handleDescribeBusinessChnage}
                  ></InputText>
                </>
              ) : (
                <ButtonComponent
                  outline={true}
                  name="My business type is not listed here"
                  textKey={"my_business_type"}
                  onClick={() => {
                    setShowNotListedBox(true);
                  }}
                />
              )}
            </div>
            <div className="stretch-container">
              <ButtonComponent
                name="Save"
                isEnabled={disableSave}
                onClick={handleSave}
                textKey={"save"}
              />
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default BusinessType;
