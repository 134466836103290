import React, { useState } from "react";
import { IonPopover, IonButton, IonImg } from "@ionic/react";
import "./style.scss";

interface Props {
  children: any;
  onClose?: () => void;
  customClass?: string;
}

const Popup: React.FC<Props> = ({ children, onClose, customClass }) => {
  const [popoverState, setShowPopover] = useState({
    showPopover: true,
    event: undefined,
  });

  return (
    <>
      <IonPopover
        backdropDismiss={false}
        //cssClass="ion-popup"
        cssClass={` ion-popup ${customClass || ""}`}
        event={popoverState.event}
        isOpen={popoverState.showPopover}
        onWillDismiss={() => {
          setShowPopover({ showPopover: false, event: undefined });
          onClose && onClose();
        }}
      >
        {children}
      </IonPopover>
    </>
  );
};

export default Popup;
