import SQLiteService from "../sqlite.service";

const TABLE_NAME = "activity";
const COLUMN_ID = "id";
const COLUMN_ACTIVITY_ID = "activityId";
const COLUMN_TOPIC_ID = "topicId";
const COLUMN_ACTIVITY_DATA = "activityData";
const COLUMN_IS_COMPLETED = "isCompleted";
const COLUMN_OUTCOMES = "outcomes";

const TABLE_CREATE =
  "create table if not exists " +
  TABLE_NAME +
  "(" +
  COLUMN_ID +
  " INTEGER PRIMARY KEY AUTOINCREMENT, " +
  COLUMN_ACTIVITY_ID +
  " INTEGER, " +
  COLUMN_TOPIC_ID +
  " INTEGER, " +
  COLUMN_ACTIVITY_DATA +
  " TEXT, " +
  COLUMN_OUTCOMES +
  " TEXT, " +
  COLUMN_IS_COMPLETED +
  " boolean)";
let _singleton = true;
let _instance: OfflineActivityService;
let sqliteService: SQLiteService = SQLiteService.instance;
class OfflineActivityService {
  constructor() {
    if (_singleton) {
      throw new SyntaxError(
        "This is a singleton class. Please use OfflineActivityService.instance instead!"
      );
    }
  }

  static get instance() {
    if (!_instance) {
      _singleton = false;
      _instance = new OfflineActivityService();
      _singleton = true;
      sqliteService.createTable(TABLE_CREATE);
    }
    return _instance;
  }

  public async insertorupdate(value: any) {
    const resp = await this.getData(value.activityId);
    let result;
    if (!resp.error) {
      if (resp.length > 0) {
        result = await this.update(value, value.activityId);
      } else {
        let values = "";
        values += "" + value.activityId;
        values += "," + value.topicId;
        values += ",'" + value.data;
        values += "','" + value.outcomes;
        values += "'," + value.isCompleted + "";

        let col =
          COLUMN_ACTIVITY_ID +
          ", " +
          COLUMN_TOPIC_ID +
          ", " +
          COLUMN_ACTIVITY_DATA +
          ", " +
          COLUMN_OUTCOMES +
          ", " +
          COLUMN_IS_COMPLETED;
        result = await sqliteService.insertWithCustom(TABLE_NAME, col, values);
        console.log("res", result);
      }
    }
    return result;
  }

  public async update(value: any, id: any) {
    let result;
    result = await sqliteService.update(
      `update ${TABLE_NAME} set ${COLUMN_ACTIVITY_DATA} = '${value.data}',${COLUMN_OUTCOMES} = '${value.outcomes}',${COLUMN_IS_COMPLETED} = ${value.isCompleted} where ${COLUMN_ACTIVITY_ID} = ${id}`
    );
    return result;
  }

  public async getData(activityId: any) {
    let query = `select * from ${TABLE_NAME} where ${COLUMN_ACTIVITY_ID} = ${activityId}`;
    let result: any = await sqliteService.getData(query);
    return result;
  }

  public async markActivityComplete(id: any) {
    let result;
    result = await sqliteService.update(
      `update ${TABLE_NAME} set ${COLUMN_IS_COMPLETED} = true where ${COLUMN_ACTIVITY_ID} = ${id} and ${COLUMN_IS_COMPLETED} != true`
    );
    return result;
  }

  public async getPendingActivity(topicId: any) {
    let query = `select * from ${TABLE_NAME} where ${COLUMN_TOPIC_ID} = ${topicId} and ${COLUMN_IS_COMPLETED} != true`;
    let result: any = await sqliteService.getData(query);
    return result;
  }
}

export default OfflineActivityService;
