import { convertUTCToLocal } from "../../utils/common.helper";
import { analytics, commit } from "../contentapi.service";
import localnotificationService from "../localnotification.service";
import observerService from "../observer.service";
import { markActivityComplete } from "../program.service";
import SQLiteService from "../sqlite.service";
import { addOvanteTask } from "../todo.service";
import { addCoins } from "../user.service";

const TABLE_NAME = "sync";
const COLUMN_ID = "id";
const COLUMN_ENTITY_ID = "entityId";
const COLUMN_ENTITY_NAME = "entityName";
const COLUMN_IS_SYNC = "isSync";
const COLUMN_CREATED_AT = "createdAt";
const COLUMN_UPDATED_AT = "updatedAt";

const TABLE_CREATE =
  "create table if not exists " +
  TABLE_NAME +
  "(" +
  COLUMN_ID +
  " INTEGER PRIMARY KEY AUTOINCREMENT, " +
  COLUMN_ENTITY_ID +
  " int, " +
  COLUMN_ENTITY_NAME +
  " TEXT," +
  COLUMN_IS_SYNC +
  " boolean default false," +
  COLUMN_CREATED_AT +
  " TEXT DEFAULT (strftime('%s','now','localtime')) ," +
  COLUMN_UPDATED_AT +
  " TEXT DEFAULT (strftime('%s','now','localtime')))";

let _singleton = true;
let _instance: SyncService;
let sqliteService: SQLiteService = SQLiteService.instance;
class SyncService {
  constructor() {
    if (_singleton) {
      throw new SyntaxError(
        "This is a singleton class. Please use SyncService.instance instead!"
      );
    }
  }

  static get instance() {
    if (!_instance) {
      _singleton = false;
      _instance = new SyncService();
      _singleton = true;
      sqliteService.createTable(TABLE_CREATE);
    }
    return _instance;
  }

  public async insert(value: any) {
    const resp = await this.isExist(value.entityId, value.entityName);
    let result;
    if (!resp.error) {
      if (resp.length > 0) {
      } else {
        let values = "";
        values += "" + value.entityId;
        values += ",'" + value.entityName + "'";
        let col = COLUMN_ENTITY_ID + ", " + COLUMN_ENTITY_NAME;
        result = await sqliteService.insertWithCustom(TABLE_NAME, col, values);
      }
    }

    return result;
  }

  public async isExist(entityId: any, entityName: string) {
    let query = `select * from ${TABLE_NAME} where ${COLUMN_ENTITY_ID} = ${entityId} and ${COLUMN_ENTITY_NAME} = '${entityName}' and ${COLUMN_IS_SYNC} = 0`;
    let result: any = await sqliteService.getData(query);
    return result;
  }

  public async changeStatus(entityId: any, entityName: string) {
    let result: any = await sqliteService.update(
      `update ${TABLE_NAME} set ${COLUMN_IS_SYNC} = 1 where ${COLUMN_ENTITY_ID} = ${entityId} and ${COLUMN_ENTITY_NAME} = '${entityName}'`
    );
    return result;
  }

  public async syncToServer() {
    let query = `select * from ${TABLE_NAME} where ${COLUMN_IS_SYNC} = 0`;
    let result: any = await sqliteService.getData(query);
    if (result && !result.error && result.length > 0) {
      observerService.handleEvents("sync", { isSyncing: true });
      setTimeout(async () => {
        for (let i = 0; i < result.length; i++) {
          let item = result.item(i);
          let query = `select * from ${item[COLUMN_ENTITY_NAME]} where id = ${item[COLUMN_ENTITY_ID]}`;
          let entityResult: any = await sqliteService.getData(query);
          if (entityResult && !entityResult.error && entityResult.length > 0) {
            let entityItem = entityResult.item(0);
            switch (item[COLUMN_ENTITY_NAME]) {
              case "todo":
                let isCompleted = false;
                if (entityItem.isCompleted) {
                  isCompleted = entityItem.isCompleted ? true : false;
                }
                let isReminder = false;
                if (entityItem.isReminder) {
                  isReminder = entityItem.isReminder ? true : false;
                }
                let reqObj = {
                  title: entityItem.title || "",
                  dueDate: entityItem.dueDate ? entityItem.dueDate : null,
                  machineName: entityItem.machineName,
                  activityId: entityItem.activityId,
                  type: entityItem.type,
                  isCompleted,
                  isReminder,
                };

                const resp: any = await addOvanteTask(reqObj);
                if (!resp.error) {
                  if (resp.isReminder && resp.dueDate) {
                    localnotificationService.schedule({
                      id: resp.id,
                      title: "Reminder",
                      text: resp.title,
                      autoClear: true,
                      data: { type: "reminder", id: entityItem.activityId },
                      trigger: {
                        at: new Date(convertUTCToLocal(resp.dueDate)),
                      },
                      priority: 2,
                    });
                  }
                  await this.changeStatus(
                    item[COLUMN_ENTITY_ID],
                    item[COLUMN_ENTITY_NAME]
                  );
                }
                break;

              case "analytics":
                let req = {
                  type: entityItem.type,
                  programId: entityItem.programId,
                  topicId: entityItem.topicId,
                  activityId: entityItem.activityId,
                  key: entityItem.key,
                  value: entityItem.value,
                };
                const analyticResp: any = await analytics(req);
                if (!analyticResp.error) {
                  await this.changeStatus(
                    item[COLUMN_ENTITY_ID],
                    item[COLUMN_ENTITY_NAME]
                  );
                }
                break;

              case "content":
                let contentReq = {
                  activityId: entityItem.activityId,
                  bookmarkData: entityItem.bookmarkData
                    ? typeof entityItem.bookmarkData === "string"
                      ? entityItem.bookmarkData
                      : JSON.parse(entityItem.bookmarkData)
                    : "",
                  sessionStore: JSON.parse(entityItem.sessionStore) || {},
                  isCompleted: entityItem.isCompleted,
                  sessionId: 0,
                };

                const contentResp: any = await commit(contentReq);
                if (!contentResp.error) {
                  await this.changeStatus(
                    item[COLUMN_ENTITY_ID],
                    item[COLUMN_ENTITY_NAME]
                  );
                }
                break;

              case "activity":
                const activityResp: any = await markActivityComplete({
                  id: entityItem.activityId,
                  responseNumber: 3,
                });
                if (!activityResp.error) {
                  await this.changeStatus(
                    item[COLUMN_ENTITY_ID],
                    item[COLUMN_ENTITY_NAME]
                  );
                }
                break;
              case "coins":
                if (entityItem.type == "add") {
                  const resp: any = await addCoins({
                    coins: entityItem.coins,
                  });
                  if (resp && !resp.error) {
                    await this.changeStatus(
                      item[COLUMN_ENTITY_ID],
                      item[COLUMN_ENTITY_NAME]
                    );
                  }
                }
                break;
            }
          }
        }
        observerService.handleEvents("sync", { isSyncing: false });
      }, 2000);
    }
  }
}

export default SyncService;
