import { Plugins } from "@capacitor/core";
import { DatePickerPluginInterface } from "@capacitor-community/date-picker";

const DatePicker: DatePickerPluginInterface = Plugins.DatePickerPlugin as any;
export const openDatePicker = (options: any) => {
  return new Promise((resolve: any, reject: any) => {
    DatePicker.present(options).then((date) => {
      return resolve(date.value);
    });
  });
};
