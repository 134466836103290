import SQLiteService from "../sqlite.service";

const TABLE_NAME = "topic";
const COLUMN_ID = "id";
const COLUMN_TOPIC_ID = "topicId";
const COLUMN_TOPIC_DATA = "topicData";

const TABLE_CREATE =
  "create table if not exists " +
  TABLE_NAME +
  "(" +
  COLUMN_ID +
  " INTEGER PRIMARY KEY AUTOINCREMENT, " +
  COLUMN_TOPIC_ID +
  " INTEGER, " +
  COLUMN_TOPIC_DATA +
  " TEXT )";

let _singleton = true;
let _instance: OfflineTopicService;
let sqliteService: SQLiteService = SQLiteService.instance;
class OfflineTopicService {
  constructor() {
    if (_singleton) {
      throw new SyntaxError(
        "This is a singleton class. Please use OfflineTopicService.instance instead!"
      );
    }
  }

  static get instance() {
    if (!_instance) {
      _singleton = false;
      _instance = new OfflineTopicService();
      _singleton = true;
      sqliteService.createTable(TABLE_CREATE);
    }
    return _instance;
  }

  public async insertorupdate(value: any) {
    const resp = await this.getData(value.topicId);
    let result;
    if (!resp.error) {
      if (resp.length > 0) {
        result = await this.update(value.data, value.topicId);
      } else {
        let values = "";
        values += "" + value.topicId;
        values += ",'" + value.data + "'";

        let col = COLUMN_TOPIC_ID + ", " + COLUMN_TOPIC_DATA;
        result = await sqliteService.insertWithCustom(TABLE_NAME, col, values);
        console.log("res", values, col, result);
      }
    }
    return result;
  }

  public async update(data: any, id: any) {
    let result;
    result = await sqliteService.update(
      `update ${TABLE_NAME} set ${COLUMN_TOPIC_DATA} = '${data}' where ${COLUMN_TOPIC_ID} = ${id}`
    );
    return result;
  }

  public async getData(topicId: any) {
    let query = `select * from ${TABLE_NAME} where ${COLUMN_TOPIC_ID} = ${topicId}`;
    let result: any = await sqliteService.getData(query);
    return result;
  }
}

export default OfflineTopicService;
