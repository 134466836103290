import {
  Plugins,
  PushNotification,
  PushNotificationToken,
  PushNotificationActionPerformed,
  Capacitor,
} from "@capacitor/core";
import { ACCESS_TOKEN, FCM_TOKEN } from "../config/appconfig";
import {
  formatStringToJSON,
  notificationRedirect,
} from "../utils/common.helper";
import httpService from "./http.service";
import localnotificationService from "./localnotification.service";
import observerService from "./observer.service";
import storageSevice from "./storage.sevice";
import { sendDeviceData } from "./userdevice.service";

const { PushNotifications, App } = Plugins;
let isForeground = true;
export const nofiticationInit = () => {
  //   PushNotifications.requestPermission().then((permission) => {
  //     if (permission.granted) {
  //       // Register with Apple / Google to receive push via APNS/FCM
  //       PushNotifications.register();
  //     } else {
  //       // No permission for push granted
  //     }
  //   });

  registertToFCM();

  App.addListener("appStateChange", ({ isActive }) => {
    isForeground = isActive;
  });

  PushNotifications.addListener(
    "registration",
    async (token: PushNotificationToken) => {
      const access_token = await storageSevice.get(ACCESS_TOKEN);
      const fcm_token = await storageSevice.get(FCM_TOKEN);
      // console.log("storage token: " + JSON.stringify(fcm_token));
      // console.log("My token: " + JSON.stringify(token));

      if (access_token && access_token !== "") {
        if (!fcm_token) {
          await storageSevice.set(FCM_TOKEN, token.value);
          //call api
          const reqObj = {
            fcmToken: token.value,
            platform: Capacitor.getPlatform() === "web" ? "web" : "mobile",
            osType:
              Capacitor.getPlatform() === "web"
                ? "browser"
                : Capacitor.getPlatform(),
          };
          console.log("call API");
          sendDeviceData(reqObj);
        } else if (fcm_token !== token.value) {
          await storageSevice.set(FCM_TOKEN, token.value);
          // call API
          const reqObj = {
            fcmToken: token.value,
            platform: Capacitor.getPlatform() === "web" ? "web" : "mobile",
            osType:
              Capacitor.getPlatform() === "web"
                ? "browser"
                : Capacitor.getPlatform(),
          };
          console.log("call API");
          sendDeviceData(reqObj);
        }
      }
    }
  );

  PushNotifications.addListener("registrationError", (error: any) => {
    console.log("Error: " + JSON.stringify(error));
  });

  PushNotifications.addListener(
    "pushNotificationReceived",
    async (notification: PushNotification) => {
      console.log("Push received: ", notification);
      if (
        notification.data.notificationType &&
        notification.data.notificationType === "silent"
      ) {
        observerService.handleEvents("reward", {
          show: true,
          data: notification.data,
        });
      } else if (isForeground) {
        localnotificationService.schedule({
          id: new Date().getTime(),
          title: notification.title,
          text: notification.body,
          data: formatStringToJSON(notification.data.data),
          autoClear: true,
          trigger: { count: 1 },
        });
      }
    }
  );

  PushNotifications.addListener(
    "pushNotificationActionPerformed",
    async (notification: PushNotificationActionPerformed) => {
      const data = notification.notification.data;
      // console.log(
      //   "Action performed: " + JSON.stringify(notification.notification)
      // );
      const parsedData: any = formatStringToJSON(data.data);
      notificationRedirect(parsedData);
    }
  );
};

export const registertToFCM = () => {
  PushNotifications.requestPermission().then((permission) => {
    if (permission.granted) {
      // Register with Apple / Google to receive push via APNS/FCM
      PushNotifications.register();
    } else {
      // No permission for push granted
    }
  });
};

export const getAllNotificationsList = async (query: any) => {
  const response: any = await httpService.execute({
    url: `/user-notifications?${query}`,
  });
  return response;
};

export const getAllNotificationsListForHome = async () => {
  const response: any = await httpService.execute({
    url: `/user-notifications/list?limit=2`,
  });
  return response;
};
