import React, { useState } from "react";
import { IonModal, IonButton, IonContent } from "@ionic/react";

interface Props {
  showModal: boolean;
  children: any;
  customClass?: string;
  onCloseModal?: (data?: any) => void;
}
const ModalPopUp = (props: Props) => {
  const { children, customClass, onCloseModal } = props;
  const [showModal, setShowModal] = useState(props.showModal);

  const closeModal = () => {
    setShowModal(false);
    onCloseModal && onCloseModal();
  };
  return (
    <IonContent>
      <IonModal
        isOpen={showModal}
        onWillDismiss={closeModal}
        // onDidDismiss={closeModal}
        cssClass={`my-custom-class ${customClass || ""}`}
      >
        {children}
      </IonModal>
    </IonContent>
  );
};
export default ModalPopUp;
