import "./style.scss";

import { IonContent, IonImg, IonPage } from "@ionic/react";
import { Form, Formik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";

import ButtonComponent from "../../components/common/button-component";
import FormGroup from "../../components/common/formgroup";
import InputText from "../../components/common/inputtext";
import RegisterHeader from "../../components/common/register-header";
import ValidationMessage from "../../components/common/validation-message";
import { AppContext } from "../../context/app.context";
import { CheckPhoneSchema } from "../../schemas/auth.schema";
import { phoneCheck, socialLogin } from "../../services/auth.service";
import {
  formatPhoneForForm,
  formatPhoneNumber,
  formatPhoneToReadable,
  handleOnValueChange,
  isObjNullOrEmpty,
  redirectToStore,
} from "../../utils/common.helper";
import FacebookIcon from "../../assets/images/icons/icon-facebook.svg";
import GoogleIcon from "../../assets/images/icons/icon-google.svg";
import PhoneIcon from "../../assets/images/icons/icon-phone.svg";
import PolygonIcon from "../../assets/images/icons/icon-polygon.svg";
import ExternalIcon from "../../assets/images/icons/ic-external.svg";
import PlayStoreIcon from "../../assets/images/icons/ic-google-play-store.svg";
import AppStoreIcon from "../../assets/images/icons/ic-app-store.svg";
import {
  handleFacebookSignin,
  handleGoogleSingin,
} from "../../services/firebase.service";
import {
  ACCESS_TOKEN,
  APPLE_STORE_URL,
  APP_DEFAULT_SCREEN,
  INIT_STATE_APP,
  PLAY_STORE_URL,
  USER_SETTINGS,
} from "../../config/appconfig";
import storageSevice from "../../services/storage.sevice";
import Loader from "../../components/common/loader";
import { isPlatform } from "@ionic/core";
import LanguageAppText from "../../components/languageapptext";
import { changeSettings, userProfile } from "../../services/user.service";
const Register: React.FC = () => {
  const {
    toastMessage,
    language,
    setSettings,
    setUserDetails,
    setAppState,
  }: any = useContext(AppContext);
  const history: any = useHistory();
  const { state }: any = useLocation();
  const initialValues = {
    phoneNumber: state ? formatPhoneForForm(state.phoneNumber) : "",
  };
  const [loading, setLoading] = useState(false);
  const [webView, setWebView] = useState(process.env.REACT_APP_WEB == "true");
  const [fbSocialLoading, setFbSocialLoading] = useState(false);
  const [googleSocialLoading, setGoogleSocialLoading] = useState(false);

  const handleCheckNumber = async (values: any, actions: any) => {
    setLoading(true);
    const response: any = await phoneCheck({
      phoneNumber: formatPhoneToReadable(values.phoneNumber),
      sendOtp: false,
    });
    setLoading(false);
    if (!response.error) {
      let path = "/createaccount";
      if (response.isPartnerUser) {
        path = "/confirmotp";
      }
      history.replace({
        pathname: path,
        state: {
          from: response.isPartnerUser ? "matched" : "not-matched",
          otp: response.otp,
          phoneNumber: formatPhoneToReadable(values.phoneNumber),
        },
        replaceUrl: true,
      });
    } else {
      toastMessage(response.message, "primary");
      actions.setFieldError("phoneNumber", response.message);
    }
  };

  const handleLoginWithPhone = () => {
    history.push({
      pathname: "loginwithphone",
    });
  };

  const handleFBLogin = async () => {
    // if (fbSocialLoading || googleSocialLoading) return;
    // setFbSocialLoading(true);
    // const tokenResp: any = await handleFacebookSignin();
    // if (!tokenResp.error) {
    //   handlSocialLogin(tokenResp);
    // } else {
    //   setFbSocialLoading(false);
    //   toastMessage(tokenResp.message, "primary");
    // }
  };

  const handleGoogleLogin = async () => {
    if (fbSocialLoading || googleSocialLoading) return;
    setGoogleSocialLoading(true);
    const tokenResp: any = await handleGoogleSingin();
    if (!tokenResp.error) {
      handlSocialLogin(tokenResp);
    } else {
      setGoogleSocialLoading(false);
      toastMessage(tokenResp.message, "primary");
    }
  };

  const handlSocialLogin = async (token: any) => {
    const resp: any = await socialLogin(token);
    if (!resp.error) {
      await storageSevice.set(ACCESS_TOKEN, resp.token);
      const respProfile: any = await userProfile();
      if (!respProfile.error) {
        const settingData = {
          notifications: respProfile.settings.notifications,
          languageCode: language,
        };
        const resp: any = await changeSettings(settingData);
        setLoading(false);
        await storageSevice.set(USER_SETTINGS, JSON.stringify(settingData));
        setSettings(settingData);
        setAppState(INIT_STATE_APP);
        setUserDetails(respProfile);
      }
      setGoogleSocialLoading(false);
      setFbSocialLoading(false);
      history.replace({
        pathname: APP_DEFAULT_SCREEN,
      });
    } else {
      setGoogleSocialLoading(false);
      setFbSocialLoading(false);
      let errMsg = resp.message;
      if (resp.status == 404) errMsg = "No user found.";
      toastMessage(errMsg, "primary");
    }
  };

  const onOpenApp = () => {
    // setTimeout(function () {
    //   if (isPlatform("ios")) {
    //     window.location.href = APPLE_STORE_URL;
    //   } else {
    //     window.location.href = PLAY_STORE_URL;
    //   }
    // }, 25);
    // window.location.href = "ovante://";

    var original_start: any = new Date();
    var lag = 1250;

    var deeplink_url = "ovante://";
    window.location.href = deeplink_url;

    setTimeout(function () {
      var newTime: any = new Date();
      var time_spent_waiting: any = newTime - original_start;
      console.log(time_spent_waiting);
      if (time_spent_waiting > 2.0 * lag) {
        // We can assume they have the app, so do nothing.
      } else {
        // That was too fast so we can assume they don't have the app.
        if (isPlatform("ios")) {
          window.location.href = APPLE_STORE_URL;
        } else {
          window.location.href = PLAY_STORE_URL;
        }
      }
    }, lag);
  };

  return (
    <IonPage
      className={`page-register ${webView ? "page-register-white" : ""}`}
    >
      {/* className="page-register"> */}
      <RegisterHeader
        pageName="Get started"
        textKey={"get_started"}
        backBtn={false}
      ></RegisterHeader>
      <IonContent fullscreen={true} className="page-content">
        <div
          className={`${
            webView
              ? "page-content-register stretch-container bg-light-orange pb-80"
              : ""
          }`}
        >
          <div className="page-content">
            <LanguageAppText
              tag={"h1"}
              className="page-content__title p0"
              value={"Create a new account"}
              textKey={"create_new_acc"}
            />
            <Formik
              enableReinitialize={false}
              initialValues={initialValues}
              validationSchema={CheckPhoneSchema}
              onSubmit={handleCheckNumber}
            >
              {({ values, setFieldValue, isValid, dirty, errors }) => (
                <Form>
                  <FormGroup>
                    <InputText
                      label="Enter your phone number"
                      labelKey={"enter_your_phone"}
                      name="phoneNumber"
                      value={formatPhoneNumber(values.phoneNumber)}
                      onChange={(val: string) => {
                        handleOnValueChange("phoneNumber", val, setFieldValue);
                      }}
                      maxlength={11}
                      prefix={"+91"}
                      inputMode={"numeric"}
                    ></InputText>
                    <ValidationMessage name="phoneNumber"></ValidationMessage>
                  </FormGroup>
                  <FormGroup>
                    <div className="page-content__button">
                      {loading ? (
                        <Loader />
                      ) : (
                        <ButtonComponent
                          name="Next"
                          textKey={"next"}
                          isEnabled={
                            !isObjNullOrEmpty(errors) ||
                            values.phoneNumber == ""
                          }
                        />
                      )}
                    </div>
                  </FormGroup>
                </Form>
              )}
            </Formik>
          </div>
          <div className="page-content">
            <LanguageAppText
              tag={"h1"}
              className="page-content__title"
              value={"I already have an account"}
              textKey={"already_have_acc"}
            />
            {!webView && (
              <>
                <div
                  className="page-content__whitebox"
                  onClick={handleLoginWithPhone}
                >
                  <IonImg className="whitebox__socialicon" src={PhoneIcon} />
                  <LanguageAppText
                    tag={"p"}
                    className="whitebox__content"
                    value={"Log in with phone number"}
                    textKey={"log_in_with_phone"}
                  />
                  <IonImg src={PolygonIcon} />
                </div>
                <div className="page-content__whitebox" onClick={handleFBLogin}>
                  <IonImg className="whitebox__socialicon" src={FacebookIcon} />
                  <LanguageAppText
                    tag={"p"}
                    className="whitebox__content"
                    value={"Log in with Facebook"}
                    textKey={"log_in_with_facebook"}
                  />
                  {fbSocialLoading ? <Loader /> : <IonImg src={PolygonIcon} />}
                </div>
                <div
                  className="page-content__whitebox"
                  onClick={handleGoogleLogin}
                >
                  <IonImg className="whitebox__socialicon" src={GoogleIcon} />
                  <LanguageAppText
                    tag={"p"}
                    className="whitebox__content"
                    value={"Log in with Google"}
                    textKey={"log_in_with_google"}
                  />
                  {googleSocialLoading ? (
                    <Loader />
                  ) : (
                    <IonImg src={PolygonIcon} />
                  )}
                </div>
              </>
            )}
            {webView && (
              <div
                className="page-content__whitebox web-register-whitebox mb-0"
                onClick={() => {
                  onOpenApp();
                }}
              >
                <LanguageAppText
                  tag={"p"}
                  className="whitebox__content"
                  value={"Open the Ovante app"}
                  textKey={"open_app"}
                />
                <IonImg className="web-external" src={ExternalIcon} />
              </div>
            )}
          </div>
        </div>
        {webView && (
          <div className="page-content_web-botton-container stretch-container bg-white">
            <LanguageAppText
              tag={"p"}
              className="page-content_web-desc mb-15 page-content__title"
              value={"Don’t have the app?"}
              textKey={"dont_have_app"}
            />
            <IonImg
              onClick={() => {
                redirectToStore("android");
              }}
              className="web-playstore mb-15"
              src={PlayStoreIcon}
            />
            <IonImg
              onClick={() => {
                redirectToStore("ios");
              }}
              className="web-appstore"
              src={AppStoreIcon}
            />
          </div>
        )}
      </IonContent>
    </IonPage>
  );
};

export default Register;
