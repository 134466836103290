import "./style.scss";
import {
  IonCheckbox,
  IonItem,
  IonLabel,
  IonRadioGroup,
  IonRadio,
} from "@ionic/react";
import React, { useContext, useEffect, useRef, useState } from "react";

interface Props {
  onValueChange?: any;
  selectedItem?: any;
  optionsList: any;
}

const RadioGroup: React.FC<Props> = ({
  optionsList,
  onValueChange,
  selectedItem,
}) => {
  const [selected, setSelected] = useState(selectedItem);
  useEffect(() => {
    setSelected(selectedItem);
  }, [optionsList]);
  return (
    <>
      <IonRadioGroup
        className="ion-radio-group"
        value={selected}
        onIonChange={(e) => {
          if (onValueChange) {
            onValueChange(e.detail.value);
          }
          setSelected(e.detail.value);
        }}
      >
        {optionsList.map((item: any, index: number) => {
          return (
            <IonItem className="ion-item mb-10" key={index}>
              <IonLabel className="ion-label">{item.value}</IonLabel>
              <IonRadio slot="start" value={item.id} />
            </IonItem>
          );
        })}
      </IonRadioGroup>
    </>
  );
};

export default RadioGroup;
