import React from "react";
import "./style.scss";
import { IonCheckbox, IonItem, IonLabel } from "@ionic/react";

interface Props {
  label: string;
  onClick?: any;
  isChecked?: boolean;
}

const CheckBox: React.FC<Props> = ({ label, onClick, isChecked }) => {
  return (
    <>
      <IonItem className="ion-item">
        <IonCheckbox
          slot="start"
          className="checkbox"
          onClick={onClick}
          checked={isChecked}
        ></IonCheckbox>
        <IonLabel className="ion-label">{label}</IonLabel>
      </IonItem>
    </>
  );
};

export default CheckBox;
