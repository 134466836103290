import { BehaviorSubject } from 'rxjs'

let _singleton = true
let _instance: ObserverService

class ObserverService {
  observable: BehaviorSubject<any>
  constructor() {
    if (_singleton) {
      throw new SyntaxError(
        'This is a singleton class. Please use ObserverService.instance instead!'
      )
    }
    this.observable = new BehaviorSubject<any>(null)
  }
  static get instance(): ObserverService {
    if (!_instance) {
      _singleton = false
      _instance = new ObserverService()
      _singleton = true
    }
    return _instance
  }

  handleEvents(type:string, data?: any) {
    this.observable.next({ type: type, data: data ?? {} })
  }
}

export default ObserverService.instance
