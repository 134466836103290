import { IonContent, IonImg, IonPage, useIonRouter } from "@ionic/react";
import { Formik, Form } from "formik";
import React, { useContext, useState } from "react";
import { useHistory, useLocation } from "react-router";
import ButtonComponent from "../../components/common/button-component";
import BackButton from "../../components/common/button-component/back";
import FormGroup from "../../components/common/formgroup";
import InputText from "../../components/common/inputtext";
import RegisterHeader from "../../components/common/register-header";
import ValidationMessage from "../../components/common/validation-message";
import LanguageAppText from "../../components/languageapptext";
import Messages from "../../config/messages";
import { AppContext } from "../../context/app.context";
import {
  CheckPhoneSchema,
  OtherPhoneNumbersSchema,
} from "../../schemas/auth.schema";
import { phoneCheck } from "../../services/auth.service";
import {
  formatPhoneNumber,
  formatPhoneToReadable,
  handleOnValueChange,
  isNullOrEmptyText,
} from "../../utils/common.helper";

const OtherNumbers: React.FC = () => {
  const { toastMessage }: any = useContext(AppContext);
  const history: any = useHistory();
  const { state }: any = useLocation();
  const [btnText, setBtnText] = useState({
    btnKey: "dont_have_number",
    btnValue: "I do not have any other numbers",
  });
  const [otherNumbers, setOtherNumbers]: any = useState({
    otherNumber1: "",
    otherNumber2: "",
  });

  const initialValues: any = {
    validateNumbers: false,
    otherNumber1: "",
    otherNumber2: "",
  };

  const handleNext = async (values: any, actions: any) => {
    const phoneNumbers: any = {
      phoneNumber1: formatPhoneToReadable(state.phoneNumber),
      phoneNumber2: formatPhoneToReadable(otherNumbers.otherNumber1),
      phoneNumber3: formatPhoneToReadable(otherNumbers.otherNumber2),
    };
    isNullOrEmptyText(otherNumbers.otherNumber1) &&
      delete phoneNumbers.phoneNumber2;
    isNullOrEmptyText(otherNumbers.otherNumber2) &&
      delete phoneNumbers.phoneNumber3;
    if (
      !isNullOrEmptyText(otherNumbers.otherNumber1) ||
      !isNullOrEmptyText(otherNumbers.otherNumber2)
    ) {
      history.replace({
        pathname: "/prefferednumber",
        state: {
          phoneNumbers: JSON.stringify(phoneNumbers),
        },
      });
    } else {
      const resp: any = await phoneCheck({
        phoneNumber: state.phoneNumber,
        sendOtp: true,
      });
      if (!resp.error) {
        history.replace({
          pathname: "/confirmotp",
          state: {
            from: "not-matched",
            otp: resp.otp,
            phoneNumber: formatPhoneToReadable(state.phoneNumber),
            phoneNumbers: JSON.stringify(phoneNumbers),
          },
        });
      } else {
        toastMessage(resp.message, "primary");
      }
    }
  };

  const handleBtnText = (value: any, inputName: string, setFieldValue: any) => {
    const numbers = otherNumbers;
    numbers[inputName] = value;
    if (numbers.otherNumber1 != "" || numbers.otherNumber2 != "") {
      setFieldValue("validateNumbers", true);
      setBtnText({
        btnKey: "next",
        btnValue: "Next",
      });
    } else {
      setBtnText({
        btnKey: "dont_have_number",
        btnValue: "I do not have any other numbers",
      });
      setFieldValue("validateNumbers", false);
    }
    setOtherNumbers(numbers);
  };

  return (
    <IonPage className="page-other-numbers">
      <RegisterHeader
        pageName="Get started"
        textKey={"get_started"}
        backBtn={true}
      ></RegisterHeader>
      <IonContent fullscreen className="page-content">
        <LanguageAppText
          tag={"h1"}
          className="page-content__title"
          value={"Do you have any other phone numbers?"}
          textKey={"have_other_phone"}
        />
        <LanguageAppText
          tag={"p"}
          className="page-content__infotext p0"
          value={
            "We could not find your number in our records. Please tell us if you have any other phone numbers."
          }
          textKey={"phone_not_matching"}
        />
        <Formik
          enableReinitialize={false}
          initialValues={initialValues}
          validationSchema={OtherPhoneNumbersSchema}
          onSubmit={handleNext}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <FormGroup>
                <InputText
                  label="Other phone number"
                  labelKey={"other_phone"}
                  name="otherNumber1"
                  value={formatPhoneNumber(values.otherNumber1)}
                  onChange={(val: string) => {
                    handleOnValueChange("otherNumber1", val, setFieldValue);
                    handleBtnText(val, "otherNumber1", setFieldValue);
                  }}
                  maxlength={11}
                  prefix={"+91"}
                  inputMode={"numeric"}
                ></InputText>
                {values.validateNumbers && (
                  <ValidationMessage name="otherNumber1"></ValidationMessage>
                )}
              </FormGroup>
              <FormGroup>
                <InputText
                  label="Other phone number"
                  labelKey={"other_phone"}
                  name="otherNumber2"
                  value={formatPhoneNumber(values.otherNumber2)}
                  onChange={(val: string) => {
                    handleOnValueChange("otherNumber2", val, setFieldValue);
                    handleBtnText(val, "otherNumber2", setFieldValue);
                  }}
                  maxlength={11}
                  prefix={"+91"}
                  inputMode={"numeric"}
                ></InputText>
                {values.validateNumbers && (
                  <ValidationMessage name="otherNumber2"></ValidationMessage>
                )}
              </FormGroup>
              <FormGroup>
                <div className="page-content__button">
                  <ButtonComponent
                    name={btnText.btnValue}
                    textKey={btnText.btnKey}
                  />
                </div>
              </FormGroup>
            </Form>
          )}
        </Formik>
      </IonContent>
    </IonPage>
  );
};

export default OtherNumbers;
