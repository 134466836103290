import SQLiteService from "./sqlite.service";

const TABLE_NAME = "variables";

const COLUMN_NAME = "name";
const COLUMN_VALUE = "value";
const COLUMN_CREATED_AT = "createdAt";
const COLUMN_UPDATED_AT = "updatedAt";

const TABLE_CREATE =
  "create table if not exists " +
  TABLE_NAME +
  "(" +
  COLUMN_NAME +
  " TEXT, " +
  COLUMN_VALUE +
  " TEXT, " +
  COLUMN_CREATED_AT +
  " TEXT DEFAULT (strftime('%s','now','localtime')) ," +
  COLUMN_UPDATED_AT +
  " TEXT DEFAULT (strftime('%s','now','localtime')))";

let _singleton = true;
let _instance: AppConfigService;
let sqliteService: SQLiteService = SQLiteService.instance;
class AppConfigService {
  constructor() {
    if (_singleton) {
      throw new SyntaxError(
        "This is a singleton class. Please use AppConfigService.instance instead!"
      );
    }
  }

  static get instance() {
    if (!_instance) {
      _singleton = false;
      _instance = new AppConfigService();
      _singleton = true;
      sqliteService.createTable(TABLE_CREATE);
    }
    return _instance;
  }

  public async insertorupdate(name: any, value: any) {
    const resp = await this.getData(COLUMN_NAME, name);
    let result;
    if (!resp.error) {
      if (resp.length > 0) {
        result = await sqliteService.update(
          `update ${TABLE_NAME} set ${COLUMN_VALUE} = '${value}' where ${COLUMN_NAME} = '${name}'`
        );
      } else {
        let values = "";
        values += "'" + name;
        values += "','" + value + "'";
        let col = COLUMN_NAME + ", " + COLUMN_VALUE;
        result = await sqliteService.insertWithCustom(TABLE_NAME, col, values);
      }
    }

    console.log("insert", result);
    return result;
  }

  public async getData(column?: string, value?: any) {
    let query = "select * from " + TABLE_NAME;
    if (column) {
      query = `select * from ${TABLE_NAME} where ${column} = '${value}'`;
    }
    let result: any = await sqliteService.getData(query);
    return result;
  }
}

export default AppConfigService;
