import HttpService, { HttpMethod } from "./http.service";

export const commit = async (reqObj: any) => {
  const response = await HttpService.execute({
    method: HttpMethod.POST,
    url: `/content-api/commit`,
    data: reqObj,
  });
  return response;
};

export const fetch = async (id: number) => {
  const response = await HttpService.execute({
    url: `/content-api/fetch/${id}`,
  });
  return response;
};

export const analytics = async (reqObj: any) => {
  const response = await HttpService.execute({
    method: HttpMethod.POST,
    url: `/node-analytics`,
    data: reqObj,
  });
  return response;
};
