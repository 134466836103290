import { Plugins } from "@capacitor/core";
import {
  IonCol,
  IonContent,
  IonGrid,
  IonImg,
  IonPage,
  IonRow,
  IonThumbnail,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import ProgressLock from "../../../assets/images/badges/home-badge-lock.svg";
import Coin from "../../../assets/images/coin.svg";
import LeaderboardCardImg from "../../../assets/images/home-leaderboard.svg";
import HomePopupIcon from "../../../assets/images/home-popup-icon.svg";
import TitleNotificationIcon from "../../../assets/images/icons/ic-notification-icon.svg";
import TitlePolygonIcon from "../../../assets/images/icons/icon-polygon.svg";
import TitleStoryIcon from "../../../assets/images/icons/ic_story_icon.svg";
import TitleToolsIcon from "../../../assets/images/icons/ic_tools_icon.svg";
import HomeProfileIcon from "../../../assets/images/icons/profile-placeholder.svg";
import ButtonComponent from "../../../components/common/button-component";
import Loader from "../../../components/common/loader";
import Popup from "../../../components/common/popup";
import ProfileImage from "../../../components/common/profileimg";
import StepBar from "../../../components/common/stepbar";
import HomeCard from "../../../components/homecard";
import HomeProfileHeader from "../../../components/homeprofileheader";
import HomeStoryCard from "../../../components/homestorycard";
import LanguageAppText from "../../../components/languageapptext";
import ToolCard from "../../../components/toolcard";
import { AppContext } from "../../../context/app.context";
import { getHighlightedStories } from "../../../services/community.service";
import { getAllNotificationsListForHome } from "../../../services/notification.service";
import { getUserCurrentProgram } from "../../../services/program.service";
import {
  getHighlightedTools,
  toolUsedOn,
} from "../../../services/tools.service";
import {
  profileSummary,
  userBadgesProgress,
} from "../../../services/user.service";
import {
  formatStringToJSON,
  getActivityMetaData,
  getFormattedUrl,
  stringInject,
} from "../../../utils/common.helper";
import "./style.scss";
const { App } = Plugins;

const Home: React.FC = () => {
  const history: any = useHistory();
  const { toastMessage, language }: any = useContext(AppContext);
  const [loading, setLoading] = useState(true);
  const [showPopup, setShowPopup] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [userBadges, setUserBadges]: any = useState(null);
  const [userDetails, setUserDetails]: any = useState(null);
  const [highlightedStories, setHighlightedStories]: any = useState([]);
  const [highlightedTools, setHighlightedTools]: any = useState(null);
  const [currentProgram, setCurrentProgram]: any = useState(null);
  const [expertMetaData, setExpertMetaData]: any = useState(null);
  const [programTeaser, setProgramTeaser]: any = useState(null);
  const [chooseText, setChooseText]: any = useState({
    textKey: "choose_first_topic",
    textValue: "Choose your first topic",
  });
  const [stepbarData, setStepbarData]: any = useState([
    {
      completed: false,
      active: true,
    },
    {
      completed: false,
      active: false,
    },
    {
      completed: false,
      active: false,
    },
    {
      completed: false,
      active: false,
    },
    {
      completed: false,
      active: false,
    },
  ]);

  useEffect(() => {
    Promise.all([
      fetchProfileDetails(),
      fetchLevelProgress(),
      fetchHighlightedStories(),
      fetchHighlightedTools(),
      fetchUserCurrentProgram(),
      fetchNotification(),
    ]).then(() => {
      setLoading(false);
    });

    document.addEventListener("ionBackButton", handleBack);

    return () => {
      document.removeEventListener("ionBackButton", handleBack);
    };
  }, []);

  const handleBack = (ev: any) => {
    ev.detail.register(100, () => {
      App.exitApp();
    });
  };

  const fetchProfileDetails = async () => {
    const resp: any = await profileSummary();
    if (!resp.error) {
      setUserDetails(resp);
    } else {
      toastMessage(resp.message, "primary");
    }
  };

  const fetchLevelProgress = async () => {
    const resp: any = await userBadgesProgress();
    console.log("fetchLevelProgress", JSON.stringify(resp));
    if (!resp.error) {
      setUserBadges(resp);
    } else {
      toastMessage(resp.message, "primary");
    }
  };

  const fetchHighlightedStories = async () => {
    const resp: any = await getHighlightedStories();
    if (!resp.error) {
      setHighlightedStories(resp);
    } else {
      toastMessage(resp.message, "primary");
    }
  };

  const fetchHighlightedTools = async () => {
    const resp: any = await getHighlightedTools();
    if (!resp.error) {
      setHighlightedTools(resp);
    } else {
      toastMessage(resp.message, "primary");
    }
  };

  const fetchUserCurrentProgram = async () => {
    const resp: any = await getUserCurrentProgram();
    if (!resp.error) {
      if (resp.defaultProgram) {
        setChooseText({
          textKey: "check_out_experts",
          textValue: "Check out the experts",
        });
        let defaultProgram = resp.defaultProgram;
        if (typeof resp.defaultProgram === "string") {
          defaultProgram = formatStringToJSON(defaultProgram);
        }
        setProgramTeaser(
          resp.defaultProgram.image[language] || resp.defaultProgram.image["en"]
        );
        let tempStepBar = stepbarData;
        tempStepBar[0] = {
          completed: false,
          active: false,
        };
        setStepbarData(tempStepBar);
      } else {
        //steps completed calculation
        const topicsCompleted: any = resp.topicsCompleted;
        let topicsCompletedLength = 0;
        let topicsCompletedArray: any[] = [];
        if (topicsCompleted && topicsCompleted != "") {
          topicsCompletedArray = topicsCompleted.split(",");
          topicsCompletedLength = topicsCompletedArray.length;
        }
        setChooseText({
          textKey: "continue_topic",
          textValue: "Continue topic",
        });
        let stepBar = [];
        for (let i = 1; i <= resp.topicCount; i++) {
          let stepObj = {
            completed: i <= topicsCompletedLength,
            active: i == topicsCompletedLength + 1,
          };
          stepBar.push(stepObj);
        }

        setCurrentProgram(resp);
        if (resp.program) {
          setStepbarData(stepBar);
          let parsedData = JSON.parse(resp.program.nodeFieldData.metaData);
          let teaserImage = JSON.parse(resp.program.nodeFieldData.teaserImage);
          if (teaserImage) {
            let imageData = teaserImage[language] || teaserImage["en"];
            setProgramTeaser(imageData);
          }
          if (parsedData) {
            let metaData = parsedData[language] || parsedData["en"];
            setExpertMetaData(metaData);
          }
        }
      }
    } else {
      toastMessage(resp.message, "primary");
    }
  };

  const fetchNotification = async () => {
    const resp: any = await getAllNotificationsListForHome();
    if (!resp.error) {
      setNotifications(resp);
    } else {
      toastMessage(resp.message, "primary");
    }
  };

  const handlePageRedirect = (route: any) => {
    history.push({
      pathname: route,
    });
  };

  const onCurrentProgram = (data: any) => {
    history.push({
      pathname: `/learnings/${data.id}`,
    });
  };

  const renderCurrentLevelView = () => {
    const currLevelBadges = userBadges.badges.map((item: any, index: any) => {
      return (
        <IonImg
          key={item.id}
          src={item.badge.image ? getFormattedUrl(item.badge.image.uri) : ""}
          className="progress-level__badge-img"
        />
      );
    });
    const lockedData = [];
    for (let i = 0; i < userBadges.remainingBadgesCount; i++) {
      lockedData.push(
        <IonImg
          key={`locked-${i}`}
          src={ProgressLock}
          className="progress-level__lock-img"
        />
      );
    }
    return [currLevelBadges, lockedData];
  };

  const onProgramClick = () => {
    if (currentProgram && currentProgram.program) {
      history.push({
        pathname: `/learnings/${currentProgram.program.id}`,
      });
    } else {
      history.push({
        pathname: `/tabs/experts`,
      });
    }
  };

  const handleViewCard = async (id: number, config: any) => {
    const configData = JSON.parse(config);
    window.open(
      configData[language] ? configData[language].url : configData["en"].url
    );
    const resp: any = await toolUsedOn(id);
  };

  const renderHighlightedStories = () => {
    return highlightedStories.map((item: any, index: any) => {
      if (item.isHighlighted) {
        return (
          <div
            onClick={() => {
              history.push({
                pathname: `/communityStory/${item.id}`,
              });
            }}
            key={"story" + index}
          >
            <HomeCard
              key={"story" + index}
              cardName={item.category.name}
              cardKey={""}
              storyCard
              storyText={item.highlightedText || item.title}
              customClass="home-story-card"
              imgPath={getFormattedUrl(item.thumbnail.uri)}
            />
          </div>
        );
      } else {
        return (
          <div
            onClick={() => {
              history.push({
                pathname: `/communityStory/${item.id}`,
              });
            }}
            key={"story" + index}
          >
            <HomeStoryCard
              key={"story" + index}
              titleText={item.category.name}
              likeCount={item.likeCount || 0}
              descriptionText={item.title}
              storyCard={true}
              imgPath={getFormattedUrl(item.thumbnail.uri)}
            />
          </div>
        );
      }
    });
  };

  const renderHighlightedTools = () => {
    return highlightedTools.map((item: any, index: any) => {
      return (
        <IonCol size="6" className="home-tools" key={"story" + index}>
          <ToolCard
            cardName={item.name}
            imgPath={item.iconImage}
            // toolLikeText={
            //   item.createdAt
            //     ? formatDateToSingleChar(
            //         moment(item.createdAt).fromNow(),
            //         item.createdAt
            //       )
            //     : ""
            // }
            onClick={() => {
              handleViewCard(item.id, item.config);
            }}
          />
        </IonCol>
      );
    });
  };

  const formatNotificationTitle = (title: string, metaData: any) => {
    let parsedTitle: any = title;
    try {
      parsedTitle = JSON.parse(title)[language] || JSON.parse(title)["en"];
      if (metaData) {
        const parsedMetaData = JSON.parse(metaData);
        const formattedData = getActivityMetaData(
          parsedMetaData.metaData,
          parsedTitle
        );
        parsedTitle = stringInject(parsedTitle, formattedData);
      }
    } catch (error) {}
    if (metaData) {
      const parsedMetaData = JSON.parse(metaData);
      parsedTitle = stringInject(parsedTitle, parsedMetaData.metaData);
    }
    return parsedTitle;
  };
  return (
    <IonPage className="page-home">
      <IonContent fullscreen className="page-content pt-0 pb-85">
        {loading ? (
          <Loader customClass="loader-page-center"></Loader>
        ) : (
          <div>
            <HomeProfileHeader
              title={
                currentProgram ? currentProgram.program.nodeFieldData.title : ""
              }
              name={expertMetaData ? expertMetaData.expertName : ""}
              bgColor={
                expertMetaData && expertMetaData.bgColor
                  ? expertMetaData.bgColor
                  : "#45b7d9"
              }
              imgPath={
                expertMetaData && expertMetaData.expertImage
                  ? getFormattedUrl(expertMetaData.expertImage.uri)
                  : null
              }
            ></HomeProfileHeader>
            <div className="home-top__container bg-white stretch-container">
              <div
                className="home-profile-progress bg-white"
                onClick={() => {
                  handlePageRedirect("/userprofile");
                }}
              >
                <IonGrid className="ion-no-margin ion-no-padding">
                  <IonRow>
                    <IonCol size="2" className="home-profile">
                      <LanguageAppText
                        tag={"div"}
                        className="home-profile-title text-spider-black font-medium mb-8"
                        value={"Profile"}
                        textKey={"profile"}
                      />
                      <ProfileImage
                        imgPath={
                          userDetails.profilePic
                            ? getFormattedUrl(userDetails.profilePic.uri)
                            : HomeProfileIcon
                        }
                      />
                      {/* <IonImg
                        src={
                          userDetails.profilePic
                            ? getFormattedUrl(userDetails.profilePic.uri)
                            : HomeProfileIcon
                        }
                        className="header-profile-img"
                      /> */}
                    </IonCol>
                    <IonCol size="7" className="home-progress-level">
                      <LanguageAppText
                        tag={"div"}
                        className="home-profile-title text-spider-black font-medium mb-8"
                        value={`Level ${userDetails.level || 1} Progress`}
                        textKey={"level_current"}
                        metaData={{ levelCurrent: userDetails.level || 1 }}
                      />
                      <div className="progress-level__badges">
                        {renderCurrentLevelView()}
                      </div>
                    </IonCol>
                    <IonCol size="3" className="home-coins pr-0">
                      <LanguageAppText
                        tag={"div"}
                        className="home-profile-title text-spider-black font-medium mb-8"
                        value={"Coins"}
                        textKey={"coins"}
                      />
                      <div className="home-available__coins">
                        <IonImg src={Coin} className="coin-img" />
                        <div className="home-coins__text text-black font-bold">
                          {userDetails.availableCoin || 0}
                        </div>
                      </div>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </div>
              <div onClick={onProgramClick}>
                <HomeCard
                  cardName={
                    currentProgram && currentProgram.program
                      ? currentProgram.program.nodeFieldData.title
                      : "No program selected"
                  }
                  cardKey={"no_program_selected"}
                  topicText={chooseText.textValue}
                  topicTextKey={chooseText.textKey}
                  imgPath={
                    programTeaser?.uri
                      ? getFormattedUrl(programTeaser.uri)
                      : undefined
                  }
                />
                <StepBar data={stepbarData} />
              </div>
            </div>
            {notifications.length > 0 && (
              <div className="home-notification__container mt-30">
                <div className="home-title__container mb-20">
                  <div className="home-title__text">
                    <IonImg
                      src={TitleNotificationIcon}
                      className="home-title-img"
                    />
                    <LanguageAppText
                      tag={"div"}
                      className="page-content__title mb-0"
                      value={"Notifications"}
                      textKey={"notifications"}
                    />
                  </div>
                  <div
                    className="home-title__text"
                    onClick={() => {
                      history.push({
                        pathname: "/notification",
                      });
                    }}
                  >
                    <LanguageAppText
                      tag={"div"}
                      className="title-right text-blue font-medium"
                      value={"View All"}
                      textKey={"view_all"}
                    />
                    <IonImg
                      src={TitlePolygonIcon}
                      className="home-title-polygon"
                    />
                  </div>
                </div>
                {notifications.map((item: any) => {
                  const title = formatNotificationTitle(
                    item.title,
                    item.metaData
                  );
                  return (
                    <HomeStoryCard
                      key={item.id}
                      titleText={title}
                      descriptionText={formatNotificationTitle(
                        item.subText,
                        item.metaData
                      )}
                      metaData={item.metaData}
                      notificationCard={true}
                      isClickable
                      imgPath={
                        item.imageUrl ? getFormattedUrl(item.imageUrl) : ""
                      }
                    />
                  );
                })}
              </div>
            )}
            <div className="home-story__container mt-30">
              <div className="home-title__container">
                <div className="home-title__text">
                  <IonImg src={TitleStoryIcon} className="home-title-img" />
                  <LanguageAppText
                    tag={"div"}
                    className="page-content__title mb-0"
                    value={"Stories"}
                    textKey={"stories"}
                  />
                </div>
                <div className="home-title__text">
                  <div
                    onClick={() => {
                      handlePageRedirect("/tabs/stories");
                    }}
                  >
                    <LanguageAppText
                      tag={"span"}
                      className="title-right text-blue font-medium"
                      value={"Explore"}
                      textKey={"explore"}
                    />
                  </div>
                  <IonImg
                    src={TitlePolygonIcon}
                    className="home-title-polygon"
                  />
                </div>
              </div>
              {highlightedStories &&
                highlightedStories.length > 0 &&
                renderHighlightedStories()}
              <div
                className="home-story-card__container mt-10 bg-white"
                onClick={() => {
                  handlePageRedirect("/leaderboard");
                }}
              >
                <div className="home-story-card-thumbnail mr-10">
                  <IonThumbnail
                    slot="start"
                    className="home-story-card-thumbnail-container"
                  >
                    <img
                      className="home-story-card-thumbnail-img"
                      src={LeaderboardCardImg}
                    />
                  </IonThumbnail>
                </div>
                {/* <IonImg src={LeaderboardCardImg} className="story-img mr-10" /> */}
                <div className="story-card__details">
                  <LanguageAppText
                    tag={"div"}
                    className="leaderboard-description text-light-black font-medium mt-5"
                    value={"See where you stand in the leaderboard today!"}
                    textKey={"leaderboard_today"}
                  />
                </div>
              </div>
            </div>
            {highlightedTools && (
              <div className="home-tools__container mt-25">
                <div className="home-title__container">
                  <div className="home-title__text">
                    <IonImg src={TitleToolsIcon} className="home-title-img" />
                    <LanguageAppText
                      tag={"div"}
                      className="page-content__title mb-0"
                      value={"Tools"}
                      textKey={"tools"}
                    />
                  </div>
                  <div className="home-title__text">
                    <div
                      onClick={() => {
                        handlePageRedirect("/tabs/tools");
                      }}
                    >
                      <LanguageAppText
                        tag={"span"}
                        className="title-right text-blue font-medium"
                        value={"Browse"}
                        textKey={"browse"}
                      />
                    </div>
                    <IonImg
                      src={TitlePolygonIcon}
                      className="home-title-polygon"
                    />
                  </div>
                </div>
                {/* <div className="home-tools__congratulation mt-10">
              <IonImg
                src={CongratulationIcon}
                className="congratulation-img"
              />
              <LanguageAppText
                tag={"div"}
                className="congratulation-text text-gray ml-6"
                value={"Congratulations! 2 new tools unlocked!{" "}"}
                textKey={"tool_unlocked_count"}
                metaData={{ unlockedToolCount:2 }}
              /> 
              <div className="congratulation-text text-gray ml-6">
                Congratulations! 2 new tools unlocked!{" "}
              </div>
            </div> */}
                <IonGrid className="ion-no-margin ion-no-padding">
                  <IonRow>{renderHighlightedTools()}</IonRow>
                </IonGrid>
              </div>
            )}
          </div>
        )}
        {showPopup && (
          <Popup
            customClass="home-success-popup"
            onClose={() => {
              setShowPopup(false);
            }}
          >
            <div className="home-popup-container">
              <IonImg src={HomePopupIcon} className="home-popup-icon mb-10" />
              <LanguageAppText
                tag={"div"}
                className="home-popup-title text-deep-black font-bold mb-10"
                value={"You’ve leveled up!"}
                textKey={"leveled_up"}
              />
              <LanguageAppText
                tag={"div"}
                className="home-popup-congratulation text-blue font-medium mb-10"
                value={`Congratulations on reaching Level 3! ${" "}`}
                textKey={"leveled_up_success"}
                metaData={{ levelCurrent: 3 }}
              />
              <LanguageAppText
                tag={"div"}
                className="home-popup-description text-light-black font-medium mb-20"
                value={"Check out the new tools and rewards you have unlocked."}
                textKey={"check_new_tools"}
              />
              <ButtonComponent
                name="Okay"
                textKey={"okay"}
                customClass="home-popup-btn"
              />
            </div>
          </Popup>
        )}
      </IonContent>
    </IonPage>
  );
};

export default Home;
