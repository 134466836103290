import * as Yup from "yup";
import Messages from "../config/messages";

const phoneRegExp = /^(?:(?=[a-z0-9-]{11}$)[a-z0-9]*-[a-z0-9]*|[a-z0-9]{10})$/

export const CheckPhoneSchema = Yup.object().shape({
    phoneNumber: Yup.string()
        .required(Messages.required_field("Phone Number")).matches(phoneRegExp, Messages.not_valid_field_val("Phone Number")),
});

export const OtherPhoneNumbersSchema = Yup.object().shape({
    validateNumbers: Yup.boolean(),
    otherNumber1: Yup.string().when('validateNumbers', {
        is: true,
        then: Yup.string().matches(phoneRegExp, Messages.not_valid_field_val("Phone Number")),
        otherwise: Yup.string()
    }),
    otherNumber2: Yup.string().when('validateNumbers', {
        is: true,
        then: Yup.string().matches(phoneRegExp, Messages.not_valid_field_val("Phone Number")),
        otherwise: Yup.string()
    })
})

export const OtpSchema = Yup.object().shape({
    otp: Yup.number().test('len', Messages.enter_valid_field_val('OTP'), (val: any) => val && val.toString().length === 4)
})

export const ChangePhoneNumbersSchema = Yup.object().shape({
    validatePhone1: Yup.boolean(),
    validatePhone2: Yup.boolean(),
    validatePhone3: Yup.boolean(),
    phoneNumber1: Yup.string().when('validatePhone1', {
        is: true,
        then:Yup.string().matches(phoneRegExp, Messages.not_valid_field_val("Phone Number")),
        otherwise: Yup.string()
    }),
    phoneNumber2: Yup.string().when('validatePhone2', {
        is: true,
        then:Yup.string().matches(phoneRegExp, Messages.not_valid_field_val("Phone Number")),
        otherwise: Yup.string()
    }),
    phoneNumber3: Yup.string().when('validatePhone3', {
        is: true,
        then:Yup.string().matches(phoneRegExp, Messages.not_valid_field_val("Phone Number")),
        otherwise: Yup.string()
    })
})