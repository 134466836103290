class Messages {
  static server_error = "Something went wrong. Please try after some time.";

  //Validation common Messages function
  static valid_short_field_val = (field: string) => `${field} too short.`;
  static valid_long_field_val = (field: string) => `${field} too long.`;
  static not_valid_field_val = (field: string) => `${field} not valid.`;
  static enter_valid_field_val = (field: string) => `Enter valid ${field}.`;
  static required_field = (field: string) => `${field} cannot be empty.`;
  static required_dropdown = (field: string) =>
    `Select atleast one option from ${field}.`;
  static NO_INTERNET_MSG = "No Internet Connection";
  static INTERNET_AVL_MSG = "Back to Online";
  static SERVER_ERROR = "Something went wrong. Please try again";

  //Button Texts
  static BTN_NO_OTHER_NUMBERS = "I do not have any other numbers";
  static BTN_NUMBER_CORRECT = "This phone number is correct";

  //Social Account
  static SOCIAL_ACCOUNT_ADD = "account added successfully";
  static SOCIAL_ACCOUNT_DELETED = "Account deleted successfully";

  //comment
  static COMMENT_SUBMIT = "Comment added successfully";
  static COMMENT_EDIT = "Comment updated successfully";
  static COMMENT_DELETE = "Comment deleted successfully";

  //story
  static STORY_SUBMIT = "Story submitted successfully";

  //file upload
  static FILE_UPLOAD_ERROR = "Error while uploading file";

  //server validation error messages
  static NO_PARTNER_USER_EXISTS =
    "no partner user exists with the given phone number.";

  //todo
  static ADD_TODO_ERROR = "Error while adding ToDo";
  static UPDATE_TODO_ERROR = "Error while updating ToDo";
  static ADD_TODO_SUCCESS = "Task added successfully";
  static COMPLETED_TODO_SUCCESS = "Task completed successfully";

  static CHANGE_NUMBER_SUCCESS = "Mobile number changed successfully";

  static COUPON_COPY = "Copied to clipboard";

  static DOWNLOAD_ERROR = "Failed to download content, Please try again";
  static ACCOUNT_DELETE_SUCCESS = "Account Deleted Successfully";
}

export default Messages;
