import SQLiteService from "../sqlite.service";

const TABLE_NAME = "content";
const COLUMN_ID = "id";
const COLUMN_BOOKMARK_DATA = "bookmarkData";
const COLUMN_SESSION_STORE = "sessionStore";
const COLUMN_ACTIVITY_ID = "activityId";
const COLUMN_IS_COMPLETED = "isCompleted";

const TABLE_CREATE =
  "create table if not exists " +
  TABLE_NAME +
  "(" +
  COLUMN_ID +
  " INTEGER PRIMARY KEY AUTOINCREMENT, " +
  COLUMN_BOOKMARK_DATA +
  " TEXT, " +
  COLUMN_SESSION_STORE +
  " TEXT, " +
  COLUMN_ACTIVITY_ID +
  " int, " +
  COLUMN_IS_COMPLETED +
  " boolean )";

let _singleton = true;
let _instance: OfflineContentService;
let sqliteService: SQLiteService = SQLiteService.instance;
class OfflineContentService {
  constructor() {
    if (_singleton) {
      throw new SyntaxError(
        "This is a singleton class. Please use OfflineContentService.instance instead!"
      );
    }
  }

  static get instance() {
    if (!_instance) {
      _singleton = false;
      _instance = new OfflineContentService();
      _singleton = true;
      sqliteService.createTable(TABLE_CREATE);
    }
    return _instance;
  }

  public async insertorupdate(value: any) {
    const resp = await this.getData(value.activityId);
    let result;
    if (!resp.error) {
      if (resp.length > 0) {
        result = await this.update(value, resp.item(0).id);
        return resp.item(0).id;
      } else {
        let values = "";
        values += "'" + value.bookmarkData;
        values += "','" + value.sessionStore;
        values += "'," + value.activityId;
        values += "," + value.isCompleted + "";

        let col =
          COLUMN_BOOKMARK_DATA +
          ", " +
          COLUMN_SESSION_STORE +
          ", " +
          COLUMN_ACTIVITY_ID +
          ", " +
          COLUMN_IS_COMPLETED;
        result = await sqliteService.insertWithCustom(TABLE_NAME, col, values);
      }
    }
    return result;
  }

  public async update(data: any, id: any) {
    let result;
    result = await sqliteService.update(
      `update ${TABLE_NAME} set ${COLUMN_BOOKMARK_DATA} = '${data.bookmarkData}' , ${COLUMN_SESSION_STORE} = '${data.sessionStore}' , ${COLUMN_IS_COMPLETED} = ${data.isCompleted} where ${COLUMN_ID} = ${id}`
    );
    return result;
  }

  public async getData(activityId: any) {
    let query = `select * from ${TABLE_NAME} where ${COLUMN_ACTIVITY_ID} = ${activityId} and ${COLUMN_IS_COMPLETED} = 0`;
    let result: any = await sqliteService.getData(query);
    return result;
  }
}

export default OfflineContentService;
