import React from "react";

interface Props {
  className?: string;
  seconds: any;
}
const Duration = (props: Props) => {
  const format = (seconds: any) => {
    const date = new Date(seconds * 1000);
    const hh = date.getUTCHours();
    const mm = date.getUTCMinutes();
    const ss = pad(date.getUTCSeconds());
    if (hh) {
      return `${hh}:${pad(mm)}:${ss}`;
    }
    return `${mm}:${ss}`;
  };

  const pad = (string: any) => {
    return ("0" + string).slice(-2);
  };

  const { className, seconds } = props;
  return (
    <time dateTime={`P${Math.round(seconds)}S`} className={className}>
      {format(seconds)}
    </time>
  );
};
export default Duration;
