import { IonContent, IonPage, useIonRouter } from "@ionic/react";
import { Form, Formik } from "formik";
import React, { useContext, useState } from "react";
import { useHistory, useLocation } from "react-router";
import ButtonComponent from "../../components/common/button-component";
import FormGroup from "../../components/common/formgroup";
import InputText from "../../components/common/inputtext";
import Loader from "../../components/common/loader";
import RegisterHeader from "../../components/common/register-header";
import ValidationMessage from "../../components/common/validation-message";
import LanguageAppText from "../../components/languageapptext";
import { AppContext } from "../../context/app.context";
import { CheckPhoneSchema } from "../../schemas/auth.schema";
import { loginPhoneCheck } from "../../services/auth.service";
import {
  formatPhoneForForm,
  formatPhoneNumber,
  formatPhoneToReadable,
  getQueryStrings,
  handleOnValueChange,
  isObjNullOrEmpty,
} from "../../utils/common.helper";
import "./style.scss";

const LoginWithPhone: React.FC = () => {
  const { toastMessage }: any = useContext(AppContext);
  const history: any = useHistory();
  const { state, search }: any = useLocation();
  const queryParam = getQueryStrings(search);
  const [loading, setLoading] = useState(false);
  const initialValues = {
    phoneNumber: state ? formatPhoneForForm(state.phoneNumber) : "",
  };
  const handleCheckNumber = async (values: any, actions: any) => {
    setLoading(true);
    const response: any = await loginPhoneCheck({
      phoneNumber: formatPhoneToReadable(values.phoneNumber),
    });
    setLoading(false);
    if (!response.error) {
      history.replace({
        pathname: `/confirmotp`,
        search,
        state: {
          from: "login",
          otp: response,
          phoneNumber: formatPhoneToReadable(values.phoneNumber),
        },
      });
    } else {
      toastMessage(response.message, "primary");
      actions.setFieldError("phoneNumber", response.message);
    }
  };

  return (
    <IonPage className="page-login-with-phone">
      <RegisterHeader
        pageName="Get started"
        textKey={"get_started"}
        backBtn={
          queryParam && queryParam.action && queryParam.action == "delete"
            ? false
            : true
        }
      ></RegisterHeader>
      <IonContent fullscreen className="page-content">
        <div className="page-content">
          <LanguageAppText
            tag={"h1"}
            className="page-content__title p0"
            value={"Log in with phone number"}
            textKey={"log_in_with_phone"}
          />
          <Formik
            enableReinitialize={false}
            initialValues={initialValues}
            validationSchema={CheckPhoneSchema}
            onSubmit={handleCheckNumber}
          >
            {({ values, setFieldValue, isValid, dirty, errors }) => (
              <Form>
                <FormGroup>
                  <InputText
                    label="Enter your phone number"
                    labelKey={"enter_your_phone"}
                    name="phoneNumber"
                    value={formatPhoneNumber(values.phoneNumber)}
                    onChange={(val: string) => {
                      handleOnValueChange("phoneNumber", val, setFieldValue);
                    }}
                    placeholder="Enter phone number"
                    placeholderKey={"enter_phone"}
                    maxlength={11}
                    prefix={"+91"}
                    inputMode={"numeric"}
                  ></InputText>
                  <ValidationMessage name="phoneNumber"></ValidationMessage>
                </FormGroup>
                <FormGroup>
                  <div className="page-content__button">
                    {loading ? (
                      <Loader />
                    ) : (
                      <ButtonComponent
                        name="Next"
                        textKey="next"
                        isEnabled={
                          !isObjNullOrEmpty(errors) || values.phoneNumber == ""
                        }
                      />
                    )}
                  </div>
                </FormGroup>
              </Form>
            )}
          </Formik>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default LoginWithPhone;
