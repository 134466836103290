import "./style.scss";

interface Props {
  children: any;
}

const FormGroup: React.FC<Props> = ({ children }) => {
  return <div className="formGroupContainer">{children}</div>;
};

export default FormGroup;
