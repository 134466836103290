import { useContext } from "react";
import { AppContext } from "../../context/app.context";
import parse from "html-react-parser";
import { getActivityMetaData, stringInject } from "../../utils/common.helper";

type Props = {
  tag: keyof JSX.IntrinsicElements;
  className?: any;
  value: any;
  textKey: any;
  metaData?: any;
};

const LanguageAppText: React.FC<Props> = ({
  tag,
  className,
  value,
  textKey,
  metaData,
}) => {
  const { language, descEnFile, descFile }: any = useContext(AppContext);
  const Tag = tag as keyof JSX.IntrinsicElements;
  let desc =
    descFile[textKey.toLowerCase()] ||
    descEnFile[textKey.toLowerCase()] ||
    value;
  if (metaData) {
    const formattedData = getActivityMetaData(metaData, desc, language);
    desc = stringInject(desc, formattedData);
  }
  return <Tag className={className}>{parse(desc)}</Tag>;
};

export default LanguageAppText;
