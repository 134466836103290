import { IonSpinner } from "@ionic/react";
import React from "react";
import Lottie from "react-lottie";
import animationData from "./lf30_editor_io6fjpmj.json";

interface Props {
  customClass?: string;
}

const Loader: React.FC<Props> = ({
  customClass,
}) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };
  return (
    <div className={`loader ${customClass || ""}`}>
      {/* <IonSpinner color="primary" /> */}
      <Lottie 
        options={defaultOptions}
          height={22}
          width={22}
        />
    </div>
  );
};

export default Loader;

