import moment from "moment";
import { convertUTCToLocal } from "../utils/common.helper";
import HttpService, { HttpMethod } from "./http.service";
import localnotificationService from "./localnotification.service";

export const getToDoList = async () => {
  const response = await HttpService.execute({
    url: `/todo`,
  });
  return response;
};

export const addTask = async (reqObj: any) => {
  const response = await HttpService.execute({
    method: HttpMethod.POST,
    url: `/todo`,
    data: reqObj,
  });
  return response;
};

export const addOvanteTask = async (reqObj: any) => {
  const response = await HttpService.execute({
    method: HttpMethod.POST,
    url: `/todo/ovante-task`,
    data: reqObj,
  });
  return response;
};

export const updateTask = async (id: number, reqObj: any) => {
  const response = await HttpService.execute({
    method: HttpMethod.PUT,
    url: `/todo/${id}`,
    data: reqObj,
  });
  return response;
};

export const changeTaskStatus = async (reqObj: any) => {
  const response = await HttpService.execute({
    method: HttpMethod.PATCH,
    url: `/todo/change-status`,
    data: reqObj,
  });
  return response;
};

export const changeOvanteTaskStatus = async (reqObj: any) => {
  const response = await HttpService.execute({
    method: HttpMethod.PATCH,
    url: `/todo/ovante-task/change-status`,
    data: reqObj,
  });
  return response;
};

export const getAlltodoOnLogin = async () => {
  const resp: any = await getToDoList();
  if (!resp.error) {
    resp.forEach((item: any) => {
      if (
        item.dueDate &&
        moment(convertUTCToLocal(item.dueDate)).isSameOrAfter(moment())
      ) {
        // console.log(
        //   "date",
        //   moment(convertUTCToLocal(item.dueDate)).isSameOrAfter(moment())
        // );

        if (
          item.type === "personal" ||
          (item.type === "task" && item.isReminder)
        ) {
          localnotificationService.schedule({
            id: item.id,
            title: "Reminder",
            text: item.title,
            autoClear: true,
            data: {
              type: item.type === "personal" ? "personal" : "reminder",
              id: item.type === "personal" ? 0 : item.activityId,
            },
            trigger: {
              at: new Date(convertUTCToLocal(item.dueDate)),
            },
          });
        }
      }
    });
  }
};
