import httpService, { HttpMethod } from "./http.service";

export const saveProfile = async (data: any) => {
  const resp = await httpService.execute({
    method: HttpMethod.POST,
    url: "/user/profile-save",
    data: data,
  });
  return resp;
};

export const userProfile = async () => {
  const resp = httpService.execute({
    url: "/user/profile",
  });
  return resp;
};

export const agreeToTOC = async () => {
  const resp = httpService.execute({
    url: "/user/agree-to-toc",
  });
  return resp;
};

export const profileSummary = async () => {
  const resp = httpService.execute({
    url: "/user/profile-summary",
  });
  return resp;
};

export const userBadgesData = async () => {
  const resp = httpService.execute({
    url: "/user/badges",
  });
  return resp;
};

export const userBadgesDetails = async () => {
  const resp = httpService.execute({
    url: "/user/badges/details",
  });
  return resp;
};

export const userRecentActivities = async () => {
  const resp = httpService.execute({
    url: "/user/activities",
  });
  return resp;
};

export const changePreferredNumebr = async (data: any) => {
  const resp = httpService.execute({
    url: "/user/change-preferred-number",
    method: HttpMethod.PUT,
    data: data,
  });
  return resp;
};

export const changeSettings = async (data: any) => {
  const resp = httpService.execute({
    url: "/user/change-settings",
    method: HttpMethod.PATCH,
    data: data,
  });
  return resp;
};

export const userDataCounts = async () => {
  const resp = httpService.execute({
    url: "/user/data/counts",
  });
  return resp;
};

export const userStories = async () => {
  const resp = httpService.execute({
    url: "/user/stories",
  });
  return resp;
};

export const getUserCertificates = async () => {
  const resp = httpService.execute({
    url: "/programs/completed/ids",
  });
  return resp;
};

export const getUserCertificateById = async (id: number) => {
  const resp = httpService.execute({
    url: `/programs/${id}/certificate`,
  });
  return resp;
};

export const getHelpCenter = async () => {
  const resp = httpService.execute({
    url: `/help-center`,
  });
  return resp;
};

export const addSocialToken = async (obj: any) => {
  const resp = httpService.execute({
    url: "/user/add-social-account",
    method: HttpMethod.POST,
    data: obj,
  });
  return resp;
};

export const getRecommendation = async () => {
  const resp = httpService.execute({
    url: `/user/recommendation`,
  });
  return resp;
};

export const getRecommendationQuestions = async () => {
  const resp = httpService.execute({
    url: `/user/v2/on-boarding-questions`,
  });
  return resp;
};

export const postRecommendation = async (obj: any) => {
  const resp = httpService.execute({
    url: "/user/recommendation",
    method: HttpMethod.POST,
    data: obj,
  });
  return resp;
};

export const updateUser = async (obj: any) => {
  const resp = httpService.execute({
    url: "/user",
    method: HttpMethod.PUT,
    data: obj,
  });
  return resp;
};

export const userBadgesProgress = async () => {
  const resp = httpService.execute({
    url: "/user/badges/level-progress",
  });
  return resp;
};

export const updateDisconnectSocialMedia = async (obj: any) => {
  const resp = httpService.execute({
    url: "/user/disconnect-social-media",
    method: HttpMethod.PUT,
    data: obj,
  });
  return resp;
};

export const userLoginCount = async () => {
  const resp = httpService.execute({
    url: "/user/login/count",
  });
  return resp;
};

export const userAppInstall = async () => {
  const resp = httpService.execute({
    url: "/user/app/install",
  });
  return resp;
};

export const removePicture = async (obj: any) => {
  const resp = httpService.execute({
    url: "/user/remove-profile-pic",
    method: HttpMethod.POST,
    data: obj,
  });
  return resp;
};

export const addCoins = async (obj: any) => {
  const resp = httpService.execute({
    url: "/user/add-coins",
    method: HttpMethod.POST,
    data: obj,
  });
  return resp;
};

export const reduceCoins = async (obj: any) => {
  const resp = httpService.execute({
    url: "/user/reduce-coins",
    method: HttpMethod.POST,
    data: obj,
  });
  return resp;
};

export const getHelpCenterImage = async () => {
  const resp = httpService.execute({
    url: `/help-center/image`,
  });
  return resp;
};

export const deleteAccount = async () => {
  const resp = httpService.execute({
    url: "/user/delete-account",
    method: HttpMethod.POST,
    data: {},
  });
  return resp;
};

export const checkNumber = async (phoneNumber: string) => {
  const resp = httpService.execute({
    url: `/user/check-user-number/${phoneNumber}`,
    method: HttpMethod.GET,
  });
  return resp;
};
