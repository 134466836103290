import { useContext } from "react";
import { AppContext } from "../../context/app.context";
import parse from "html-react-parser";
import { formatStringToJSON } from "../../utils/common.helper";

type Props = {
  tag: keyof JSX.IntrinsicElements;
  className?: any;
  value: any;
};

const LanguageText: React.FC<Props> = ({ tag, className, value }) => {
  const { language }: any = useContext(AppContext);
  const Tag = tag as keyof JSX.IntrinsicElements;
  let tagValue = value;
  if (typeof value === "string") {
    tagValue = formatStringToJSON(value);
  }
  return (
    <Tag className={className}>
      {typeof tagValue === "string"
        ? parse(tagValue)
        : parse(tagValue[language] || tagValue["en"])}
    </Tag>
  );
};

export default LanguageText;
