import { IonImg, IonLabel, IonItem } from "@ionic/react";
import React, { useContext } from "react";
import { useHistory } from "react-router";
import { OnboardingContext } from "../../../context/onboarding.contex";
import { handleOnValueChange } from "../../../utils/common.helper";
import FormGroup from "../../common/formgroup";
import InputText from "../../common/inputtext";
import ProfileImage from "../../common/profileimg";
import ValidationMessage from "../../common/validation-message";
import DownArrow from "../../../assets/images/icons/icon-polygon-down-arrow.svg";
import RightArrow from "../../../assets/images/icons/icon-polygon-right-arrow.svg";
import "./style.scss";
import LanguageAppText from "../../languageapptext";

interface Props {
  formik: any;
  initialValues?: any;
  gender?: string;
  salesRange?: string;
  profileImg?: string;
  profileData?: any;
  handleGenderClick?: () => void;
  handleSalesRangeClick?: () => void;
  handleEditPickClick?: () => void;
}

const UserProfileForm: React.FC<Props> = ({
  formik,
  gender,
  salesRange,
  profileImg,
  profileData,
  handleGenderClick,
  handleSalesRangeClick,
  handleEditPickClick,
}) => {
  const { values, errors, touched, setFieldValue } = formik;
  const history: any = useHistory();
  const { setOnoboardingData }: any = useContext(OnboardingContext);

  const handleTypeOfBusinessesClick = (data: any) => {
    setOnoboardingData({
      ...data,
      profileImg: profileImg,
      profileData: profileData,
    });
    history.push({
      pathname: "/onboarding/businesstype",
    });
  };

  const handleStateClick = (data: any) => {
    setOnoboardingData({
      ...data,
      profileImg: profileImg,
      profileData: profileData,
    });
    history.push({
      pathname: "/onboarding/state",
    });
  };

  const handleDistClick = (data: any) => {
    setOnoboardingData({
      ...data,
      profileImg: profileImg,
      profileData: profileData,
    });
    history.push({
      pathname: "/onboarding/district",
    });
  };

  const onEditPick = () => {
    handleEditPickClick && handleEditPickClick();
  };
  return (
    <>
      <div className="page-content__profile">
        <ProfileImage
          imgPath={profileImg}
          isEditable={true}
          onEditPick={onEditPick}
        ></ProfileImage>
      </div>
      <LanguageAppText
        tag={"h1"}
        className="page-content__title"
        value={"Personal details"}
        textKey={"personal_details"}
      />
      <form>
        <FormGroup>
          <InputText
            name="name"
            value={values.name}
            label="Name"
            labelKey={"name"}
            placeholder="Please enter your name"
            placeholderKey={"please_enter_name"}
            onChange={(val: string) =>
              handleOnValueChange("name", val, setFieldValue)
            }
          ></InputText>
          {values.name == "" && errors.name && touched.name && (
            <ValidationMessage
              name="name"
              message={errors.name}
              customValidation={true}
            ></ValidationMessage>
          )}
        </FormGroup>
        <FormGroup>
          <div className="readonly">
            <InputText
              name="gender"
              label="Gender"
              labelKey={"gender"}
              placeholder="Please choose"
              placeholderKey={"please_choose"}
              value={gender}
              onClick={() => {
                handleGenderClick && handleGenderClick();
              }}
              onChange={() => {
                handleOnValueChange("gender", gender, setFieldValue);
              }}
              readonly
            ></InputText>
            <IonImg src={DownArrow} className="readonly__down-arrow" />
          </div>

          {values.gender == "" && errors.gender && touched.gender && (
            <ValidationMessage
              name="gender"
              message={errors.gender}
              customValidation={true}
            ></ValidationMessage>
          )}
        </FormGroup>
        <FormGroup>
          <InputText
            maxlength={3}
            type="number"
            name="age"
            label="Age"
            labelKey={"age"}
            value={values.age}
            placeholder="Please enter your age"
            placeholderKey={"please_enter_your_age"}
            onChange={(val: string) =>
              handleOnValueChange("age", val, setFieldValue)
            }
          ></InputText>
          {values.age == "" && errors.age && touched.age && (
            <ValidationMessage
              name="age"
              message={errors.age}
              customValidation={true}
            ></ValidationMessage>
          )}
        </FormGroup>

        <FormGroup>
          <IonLabel className="ion-item__label ion-item__form-business">
            <LanguageAppText tag={"span"} value={"State"} textKey={"state"} />
          </IonLabel>
          <div className="readonly">
            <IonItem className="ion-item">
              <div
                className="input-textarea"
                data-placeholder="Please choose"
                onClick={() => {
                  handleStateClick(values);
                }}
              >
                {values.state}
              </div>
            </IonItem>
            <IonImg src={RightArrow} className="readonly__right-arrow" />
          </div>
          {values.state == "" && errors.state && touched.state && (
            <ValidationMessage
              name="state"
              message={errors.state}
              customValidation={true}
            ></ValidationMessage>
          )}
        </FormGroup>

        {values.state != "" && (
          <FormGroup>
            <IonLabel className="ion-item__label ion-item__form-business">
              <LanguageAppText
                tag={"span"}
                value={"District"}
                textKey={"district"}
              />
            </IonLabel>
            <div className="readonly">
              <IonItem className="ion-item">
                <div
                  className="input-textarea"
                  data-placeholder="Please choose"
                  onClick={() => {
                    handleDistClick(values);
                  }}
                >
                  {values.district}
                </div>
              </IonItem>
              <IonImg src={RightArrow} className="readonly__right-arrow" />
            </div>
            {values.district == "" && errors.district && touched.district && (
              <ValidationMessage
                name="district"
                message={errors.district}
                customValidation={true}
              ></ValidationMessage>
            )}
          </FormGroup>
        )}

        <LanguageAppText
          tag={"h1"}
          className="page-content__title"
          value={"Business Information"}
          textKey={"business_info"}
        />

        <FormGroup>
          <IonLabel className="ion-item__label ion-item__form-business">
            <LanguageAppText
              tag={"span"}
              value={"Type of Business"}
              textKey={"type_of_business"}
            />
          </IonLabel>
          <div className="readonly">
            <IonItem className="ion-item">
              <div
                className="input-textarea"
                data-placeholder="Please choose"
                onClick={() => {
                  handleTypeOfBusinessesClick(values);
                }}
              >
                {values.typeOfBusiness.replace(/\|/g, ", ")}
              </div>
            </IonItem>
            <IonImg src={RightArrow} className="readonly__right-arrow" />
          </div>
          {values.typeOfBusiness == "" &&
            errors.typeOfBusiness &&
            touched.typeOfBusiness && (
              <ValidationMessage
                name="typeOfBusiness"
                message={errors.typeOfBusiness}
                customValidation={true}
              ></ValidationMessage>
            )}
        </FormGroup>
        <FormGroup>
          <div className="readonly">
            <InputText
              name="saleRange"
              label="Monthly Sales Range"
              labelKey={"monthly_sales_range"}
              placeholder="Please choose"
              placeholderKey={"please_choose"}
              value={salesRange}
              onClick={() => {
                handleSalesRangeClick && handleSalesRangeClick();
              }}
              onChange={() => {
                handleOnValueChange("salesRange", salesRange, setFieldValue);
              }}
              readonly
            ></InputText>
            <IonImg src={DownArrow} className="readonly__down-arrow" />
          </div>
          {values.salesRange == "" &&
            errors.salesRange &&
            touched.salesRange && (
              <ValidationMessage
                name="salesRange"
                message={errors.salesRange}
                customValidation={true}
              ></ValidationMessage>
            )}
        </FormGroup>
      </form>
    </>
  );
};

export default UserProfileForm;
