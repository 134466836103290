import React, { useEffect, useRef, useState } from "react";
import "./style.scss";
interface Props {
  data: Array<any>;
}
const StepBar = (props: Props) => {
  const { data } = props;
  return (
    <div className="stepbar-wrapper">
      <ol className="progress" data-steps={data.length}>
        {data.map((item: any, index: number) => {
          let className = "";
          className = item.completed ? "done" : item.active ? "active" : "";
          return (
            <li key={index} className={className}>
              <span className="step"></span>
            </li>
          );
        })}
      </ol>
    </div>
  );
};
export default StepBar;
